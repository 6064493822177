<template>
  <div class="header">
    <div class="header-part1">
      <div class="header-back" @click="$emit('back')" />
      <div class="header-logo" />
    </div>
    <div class="header-part2">
      <div class="header-name">{{ podcastDetails.name }}</div>
      <div
        class="header-intro"
        v-html="podcastDetails.description.split('\n').join('<br />')"
      ></div>
    </div>
    <div class="header-part3">
      <div class="header-allepisodes"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["activePodcast", "podcasts"],
  computed: {
    podcastDetails() {
      return this.podcasts.find((pod) => pod.guid == this.activePodcast);
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.header-part1 {
  display: flex;
  flex-direction: row;
  height: 94px;
}
.header-back {
  border-top: 5px solid #ef7b10;
  flex-grow: 1;
  background-image: url("../../../../img/podcast/vdlande/back.svg");
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.header-logo {
  margin-right: 20px;
  flex-grow: 3;
  background-image: url("../../../../img/podcast/vdlande/logo.svg");
  background-size: 210px 55px;
  background-position: top right;
  background-clip: content-box;
  background-repeat: no-repeat;
}
.header-part2 {
  vertical-align: top;
  margin: 5px 25px;
  min-height: 94px;
}
.header-name {
  text-align: left;
  font-family: "MetaPro-Bold";
  font-size: 27px;
  line-height: 27px;
  min-height: 31px;
  margin-bottom: 5px;
}
.header-intro {
  text-align: left;
  font-family: "MetaPro-Norm";
  font-size: 13px;
  line-height: 15px;
  min-height: 45px;
}
.header-part3 {
  height: 38px;
  width: 100%;
}
.header-allepisodes {
  height: 38px;
  width: 160px;
  background-image: url("../../../../img/podcast/vdlande/allepisodes.svg");
  background-size: cover;
}
</style>