import axios from "axios";

export default {
    data() {
        return { csrf_token: null };
    },
    methods: {
        apiGet(path, forceEventSlug = null, forceWatcherGuid = null) {
            return new Promise((res, rej) => {
                return axios
                    .get(
                        "/api/frontend/event/" +
                            (forceEventSlug || this.eventInfo.slug) +
                            "/" +
                            path,
                        {
                            headers: {
                                Authorization:
                                    "watcher " +
                                    (forceWatcherGuid || this.watcher.guid),
                            },
                        }
                    )
                    .then((data) => {
                        if (data.headers["set-csrf-token"]) {
                            this.csrf_token = data.headers["set-csrf-token"];
                        }
                        res(data);
                    })
                    .catch(rej);
            });
        },

        apiPost(path, postData) {
            return axios.post(
                "/api/frontend/event/" + this.eventInfo.slug + "/" + path,
                postData,
                {
                    headers: {
                        ...(this.watcher &&
                            this.watcher.guid && {
                                Authorization: "watcher " + this.watcher.guid,
                            }),
                        ...(this.csrf_token && {
                            "X-CSRF-TOKEN": this.csrf_token,
                        }),
                    },
                }
            );
        },
    },
};
