<template>
  <div :style="style" style="text-align: center">
    <div class="you-poll-header">{{ $t("message.participate") }}</div>
    <div class="you-poll-container">
      <div
        v-if="activePoll"
        class="you-poll-content"
        :key="activePoll.guid"
        :style="containerStyle"
      >
        <div class="elemcontainer">
          <div class="question" :style="questionContentStyle">
            {{ activePoll.question }}
          </div>
        </div>
        <div
          v-for="(answer, index) in ['A', 'B', 'C', 'D', 'E', 'F']"
          :key="index"
          class="elemcontainer btn"
        >
          <div
            v-if="activePoll.answers[index]"
            @click="submitPoll(answer)"
            :class="[
              'answer',
              givenPollAnswers[activePoll.guid] == answer
                ? 'givenanswer'
                : null,
              moreThenFourAnswers ? 'answer-morethenfour' : null,
            ]"
          >
            <div class="answerstart">{{ answer }}</div>
            <div class="answercontent" :style="answerContentStyle">
              {{ activePoll.answers[index] }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="activeWordcloud"
        class="you-wordcloud-content"
        :key="activeWordcloud.guid"
        :style="containerStyle"
      >
        <div class="elemcontainer">
          <div class="question">
            {{ activeWordcloud.question }}
          </div>
        </div>
        <div>
          <div
            v-for="wordCloudAnswerNumber in activeWordcloud.answer_count"
            :key="wordCloudAnswerNumber"
            class="wordcloud-row"
          >
            <label class="wordcloud-label">{{ wordCloudAnswerNumber }}.</label>
            <input
              v-if="!givenWordcloudAnswers[activeWordcloud.guid]"
              type="text"
              :class="[
                'form-control',
                'wordcloud-input',
                wordcloudErrors.includes(wordCloudAnswerNumber - 1)
                  ? 'wordcloud-error'
                  : null,
              ]"
              :placeholder="$t('message.typeyourwordhere')"
              v-model="wordcloudResponse[wordCloudAnswerNumber - 1]"
              :maxlength="25"
            />
            <input
              v-else
              type="text"
              :class="['form-control', 'wordcloud-input']"
              v-model="
                givenWordcloudAnswers[activeWordcloud.guid][
                  wordCloudAnswerNumber - 1
                ]
              "
              :disabled="true"
            />
          </div>
        </div>
        <div class="submit-container">
          <button
            class="btn btn-message-submit"
            @click="submitWordcloud"
            v-if="
              !wordcloudSending && !givenWordcloudAnswers[activeWordcloud.guid]
            "
          >
            {{ $t("message.sendWordcloud") }}
          </button>
        </div>
      </div>
      <div
        v-if="!activePoll && !activeWordcloud"
        class="you-poll-content"
        :style="containerStyle"
      >
        <textarea
          class="form-control message-input"
          v-model="messageContent"
          :maxlength="800"
          :placeholder="$t('message.typeYourMessageHere')"
        ></textarea>
        <div class="submit-container">
          <div
            class="submit-confirmation-overlay"
            v-if="confirmationOverlayMessage"
            @click="confirmationOverlayMessage = null"
          >
            <div class="submit-confirmation-overlay-content">
              <div class="submit-confirmation-overlay-content-close">X</div>
              {{ confirmationOverlayMessage }}
            </div>
          </div>
          <button
            class="btn btn-message-submit"
            @click="submitMessage"
            v-if="!messageSending && !thankyou"
          >
            {{ $t("message.sendMessage") }}
          </button>
          <div
            class="btn btn-message-submit btn-message-thankyou"
            v-if="thankyou || messageSending"
          >
            {{ $t("message.thanksForMessage") }}
          </div>
          <div class="picture-container" v-if="$device.ios || $device.android">
            <photo-uploader
              :eventInfo="eventInfo"
              :redactionToolGuid="redactionToolGuid"
              :watcher="watcher"
              @uploadSuccess="
                confirmationOverlayMessage = $t('message.thanksForPhoto')
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="you-poll-promo"
      v-if="showQr"
      :style="{
        'background-image': eventInfo.page_interaction_qr_background_image
          ? 'url(' + eventInfo.page_interaction_qr_background_image + ')'
          : 'url(' + require('../../../img/yoe/scan-qr-interaction.png') + ')',
      }"
    ></div>
    <div
      v-if="showMobileFooter && eventInfo.mobile_footer_image"
      class="you-mobile-footer-image"
      @click="openMobileFooterLink"
      :style="{
        'background-image': 'url(' + eventInfo.mobile_footer_image + ')',
      }"
    ></div>
    <div v-if="showQr" class="you-poll-qr" @click="showBigQr = true">
      <vue-qrcode
        :value="qrUrl"
        v-if="showQr"
        class="you-poll-small-qr"
        :margin="1"
      />
    </div>
    <div
      class="you-poll-big-qr-container"
      v-if="showBigQr"
      @click="showBigQr = false"
    >
      X
      <vue-qrcode :value="qrUrl" class="you-poll-big-qr" />
    </div>
    <websocket-client
      :eventInfo="eventInfo"
      :redactionToolGuid="redactionToolGuid"
      :watcher="watcher"
      v-on:liveitemchanged="handleLiveItemChanged"
      v-on:pollanswered="handlePollAnswered"
      v-on:wordcloudanswered="handleWordcloudAnswered"
    />
  </div>
</template>
<script>
import WatcherApi from "../mixins/WatcherApi";
import VueQrcode from "vue-qrcode";
import WebsocketClient from "../components/WebsocketClient";
import PhotoUploader from "../components/PhotoUploader";

export default {
  props: [
    "eventInfo",
    "eventDetails",
    "redactionToolGuid",
    "watcher",
    "showQr",
    "showMobileFooter",
  ],
  mixins: [WatcherApi],
  components: { VueQrcode, WebsocketClient, PhotoUploader },
  data() {
    return {
      messageContent: "",
      messageSending: false,
      thankyou: false,
      showBigQr: false,
      pollQr: "/api/frontend/" + this.eventInfo.slug + "/poll/qr",
      activePoll: null,
      activeWordcloud: null,
      wordcloudResponse: [],
      wordcloudErrors: [],
      wordcloudSending: false,
      givenPollAnswers: {},
      givenWordcloudAnswers: {},
      checkSleepInterval: null,
      sleepTimer: null,
      confirmationOverlayMessage: null,
      style: {
        "--background_color": this.eventInfo.background_color,
        "--tab1_background_color": this.eventInfo.tab1_background_color,
        "--tab1_text_color": this.eventInfo.tab1_text_color,
        "--tab2_background_color": this.eventInfo.tab2_background_color,
        "--tab2_text_color": this.eventInfo.tab2_text_color,
        "--splash_text_color": this.eventInfo.splash_text_color,
        "--button_color": this.eventInfo.button_color,
        "--button_text_color": this.eventInfo.button_text_color,
        "--button_active_color": this.eventInfo.button_active_color,
        "--button_active_text_color": this.eventInfo.button_active_text_color,
        "--session_block_color": this.eventInfo.session_block_color,
        "--session_block_text_color": this.eventInfo.session_block_text_color,
        "--session_block_active_color":
          this.eventInfo.session_block_active_color,
        "--session_block_active_text_color":
          this.eventInfo.session_block_active_text_color,
        "--registration_text_color": this.eventInfo.registration_text_color,
        "--label_color": this.eventInfo.label_color,
        "--label_text_color": this.eventInfo.label_text_color,
        "--box_background_color": this.eventInfo.box_background_color,
        "--box_input_field_background_color":
          this.eventInfo.box_input_field_background_color,
        "--box_input_field_label_color":
          this.eventInfo.box_input_field_label_color,
        "--box_input_field_text_color":
          this.eventInfo.box_input_field_text_color,
        "--logout_email_text_color": this.eventInfo.logout_email_text_color,
      },
    };
  },
  computed: {
    qrUrl() {
      if (this.eventDetails.anonymous_register_token) {
        //If "show_anonymouse_qr_on_frontend" is true, show the non personal one
        return (
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port > 433 ? ":" + window.location.port : "") +
          "/" +
          this.eventInfo.slug +
          "/anonymousmobile/" +
          this.eventDetails.anonymous_register_token
        );
      } else {
        // Show personal QR
        return (
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port > 433 ? ":" + window.location.port : "") +
          "/" +
          this.eventInfo.slug +
          "/mobile/" +
          this.watcher.guid
        );
      }
    },
    containerStyle() {
      return this.showQr ? {} : { "padding-bottom": "15px" };
    },
    questionContentStyle() {
      const maxLength = this.activePoll.question
        ? this.activePoll.question.length
        : 0;
      if (maxLength > 100) {
        return { "font-size": "12px", "line-height": "14px" };
      }
      if (maxLength > 60) {
        return { "font-size": "16px", "line-height": "18px" };
      }
      return {};
    },
    answerContentStyle() {
      if (!this.activePoll.answers) {
        return {};
      }
      const maxLength = this.activePoll.answers.reduce(
        (n, answer) => (n > answer.length ? n : answer.length),
        0
      );
      if (maxLength > 100) {
        return { "font-size": "8px" };
      }
      if (maxLength > 60) {
        return { "font-size": "10px" };
      }
      return {};
    },
    moreThenFourAnswers() {
      return (
        (this.activePoll.answers[4] && this.activePoll.answers[4].length > 0) ||
        (this.activePoll.answers[5] && this.activePoll.answers[5].length > 0)
      );
    },
  },
  mounted() {
    this.getState();
    this.checkSleepInterval = setInterval(() => {
      const now = new Date().getTime();
      if (this.sleepTimer && this.sleepTimer < now - 5000) {
        this.getState();
      }
      this.sleepTimer = now;
    }, 1000);
  },
  beforeDestroy() {
    if (this.checkSleepInterval) {
      clearInterval(this.checkSleepInterval);
    }
  },
  methods: {
    handlePollAnswered(payload) {
      this.$set(this.givenPollAnswers, payload.guid, payload.answer);
    },
    handleWordcloudAnswered(payload) {
      this.$set(this.givenWordcloudAnswers, payload.guid, payload.answers);
    },
    handleLiveItemChanged(payload) {
      this.setLiveState(payload);
    },
    setLiveState(item) {
      if (item.item_type == "InteractionPoll") {
        this.$set(this, "activePoll", item);
      } else {
        this.$set(this, "activePoll", null);
      }
      if (item.item_type == "InteractionWordcloud") {
        this.$set(this, "activeWordcloud", item);
      } else {
        this.$set(this, "activeWordcloud", null);
      }
    },
    submitPoll(answer) {
      if (this.givenPollAnswers[this.activePoll.guid]) {
        //Already answered
        return;
      }
      this.$set(this.givenPollAnswers, this.activePoll.guid, answer);
      this.apiPost(
        "poll/" +
          this.redactionToolGuid +
          "/" +
          this.activePoll.guid +
          "/answer",
        {
          answer: answer,
        }
      )
        .then((res) => {})
        .catch((e) => {
          this.$delete(this.givenPollAnswers, this.activePoll.guid);
        });
    },
    submitWordcloud() {
      if (this.givenWordcloudAnswers[this.activeWordcloud.guid]) {
        //Already answered
        return;
      }
      this.wordcloudErrors = [];
      for (let index = 0; index < this.activeWordcloud.answer_count; index++) {
        if (
          !this.wordcloudResponse[index] ||
          this.wordcloudResponse[index].trim() == ""
        ) {
          this.wordcloudErrors.push(index);
        }
      }
      if (this.wordcloudErrors.length > 0) {
        return;
      }
      this.$set(
        this.givenWordcloudAnswers,
        this.activeWordcloud.guid,
        this.wordcloudResponse
      );
      if (this.wordcloudSending) {
        return false;
      }
      this.wordcloudSending = true;
      this.apiPost(
        "wordcloud/" +
          this.redactionToolGuid +
          "/" +
          this.activeWordcloud.guid +
          "/answer",
        {
          answers: this.wordcloudResponse,
        }
      )
        .then((res) => {
          this.wordcloudSending = false;
          this.wordcloudResponse = [];
        })
        .catch((e) => {
          this.wordcloudSending = false;
          this.$delete(this.givenWordcloudAnswers, this.activeWordcloud.guid);
        });
    },
    submitMessage() {
      if (this.messageSending || this.messageContent.length < 1) {
        return false;
      }
      this.messageSending = true;
      this.apiPost("interaction/" + this.redactionToolGuid + "/message", {
        message: this.messageContent,
      })
        .then((res) => {
          this.messageSending = false;
          this.messageContent = "";
          this.thankyou = true;
          setTimeout(() => {
            this.thankyou = false;
          }, 2000);
        })
        .catch((e) => {
          this.messageSending = false;
        });
    },
    getState() {
      this.apiGet("interaction/" + this.redactionToolGuid + "/state")
        .then((res) => {
          this.setLiveState(res.data.live_item);
          this.$set(this, "givenPollAnswers", res.data.given_poll_answers);
          this.$set(
            this,
            "givenWordcloudAnswers",
            res.data.given_wordcloud_answers
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openMobileFooterLink() {
      if (this.eventInfo.mobile_footer_link) {
        window.open(this.eventInfo.mobile_footer_link);
      }
    },
  },
};
</script>
<style type="text/css" scoped>
.you-poll-header {
  margin-left: 10px;
  margin-top: 5px;
  padding-top: 2px;
  font-family: "Gotham Bold";
  color: var(--label_text_color);
  font-size: 24px;
  background-color: var(--label_color);
  width: 195px;
  height: 45px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.you-poll-container {
  position: absolute;
  top: 45px;
  background-color: #dddddd;
  border-radius: 7px;
  width: 286px;
  height: 556px;
  -webkit-box-shadow: -11px 11px 13px 0px rgba(212, 116, 116, 0.35);
  -moz-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
}
.you-poll-promo {
  position: absolute;
  top: 470px;
  left: 11px;
  width: 263px;
  height: 117px;
  background-repeat: no-repeat;
}
.you-mobile-footer-image {
  position: absolute;
  top: 470px;
  left: 11px;
  width: 263px;
  height: 117px;
  background-repeat: no-repeat;
  background-position: center;
}

.you-poll-qr {
  position: absolute;
  top: 474px;
  left: 17px;
  cursor: pointer;
}

.you-poll-small-qr {
  width: 110px;
  height: 110px;
}

.you-poll-big-qr-container {
  cursor: pointer;
  position: absolute;
  top: 66px;
  left: 17px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  margin-top: 5px;
  text-align: right;
  font-family: "Gotham Bold";
  font-size: 20px;
  color: white;
  padding-top: 5px;
  padding-right: 5px;
}

.you-poll-big-qr {
  margin: 10px 5px 10px 10px;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
}

.you-poll-content {
  margin: 11px;
  width: 264px;
  height: 530px;
  background-color: var(--box_background_color);
  padding: 15px 10px 120px 10px;
  overflow-y: auto;
}

.you-wordcloud-content {
  margin: 11px;
  width: 264px;
  height: 530px;
  background-color: var(--box_background_color);
  padding: 15px 10px 120px 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.wordcloud-row {
  display: flex;
  flex-direction: row;
  justify-items: center;
  margin: 20px 0px;
}

.wordcloud-label {
  display: block;
  width: 20px;
  padding-top: 5px;
}

.wordcloud-input {
  flex-grow: 1;
}

.header {
  font-size: 14px;
}
.elemcontainer {
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 100%;
}
.question {
  text-align: center;
  font-size: 20px;
  line-height: 22px;
}
.answer {
  width: 100%;
  border-radius: 20px;
  background-color: var(--button_color);
  border: none;
  border-radius: 10px;
  font-family: "Gotham Bold";
  color: var(--button_text_color);
  padding: 10px 5px 10px 5px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  overflow: hidden;
}

.answer-morethenfour {
  padding: 0;
  height: 38px;
}

.answerstart {
  width: 30px;
  font-size: 25px;
}

.answercontent {
  width: 200px;
  overflow: hidden;
  text-align: left;
  font-weight: normal;
  margin: 0px 5px 0px 5px;
}

.givenanswer {
  background-color: var(--button_active_color);
  color: var(--button_active_text_color);
}
.message-header {
  font-size: 14px;
}
.message-input {
  height: 300px;
  margin-bottom: 20px;
  resize: none;
}
.submit-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.btn-message-submit {
  flex-grow: 1;
  background-color: var(--button_color);
  border: none;
  border-radius: 10px;
  font-family: "Gotham Bold";
  color: var(--button_text_color);
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  height: 40px;
}

.btn-message-thankyou {
  background-color: var(--button_color);
}

.picture-container {
  border: none;
  border-radius: 10px;
  background-color: var(--button_color);
  color: var(--button_text_color);
  width: 40px;
  height: 40px;
  margin-left: 10px;
  vertical-align: center;
  padding-top: 4px;
}

.wordcloud-error {
  background-color: #ff6666;
}

.submit-confirmation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #00000088;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.submit-confirmation-overlay-content {
  cursor: pointer;
  background-color: var(--button_color);
  font-family: "Gotham Bold";
  color: var(--button_text_color);
  font-size: 14px;
  padding: 10px;
}

.submit-confirmation-overlay-content-close {
  text-align: right;
}
</style>
