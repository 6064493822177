var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"you-chat-header"},[_vm._v("Event Chat")]),_vm._v(" "),_c('div',{staticClass:"you-chat-container"},[_c('div',{ref:"chatcontent",staticClass:"you-chat-content"},_vm._l((_vm.chat),function(chatmsg){return _c('div',{key:chatmsg.id,class:[
          'you-chat-message',
          chatmsg.type == 'whatsapp'
            ? 'you-chat-message-green'
            : 'you-chat-message-blue',
        ]},[_vm._v("\n        "+_vm._s(chatmsg.type == "whatsapp" ? chatmsg.message : "")+"\n        "),(chatmsg.type != 'whatsapp')?_c('ClickableLinks',{attrs:{"content":chatmsg.message}}):_vm._e()],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"you-chat-promo",style:({
      'background-image': _vm.eventInfo.page_qr_background_image
        ? 'url(' + _vm.eventInfo.page_qr_background_image + ')'
        : 'url(' + require('../../../img/yoe/scan-qr.png') + ')',
    })}),_vm._v(" "),_c('div',{staticClass:"you-chat-qr",style:({ 'background-image': 'url(' + _vm.chatQr + ')' }),on:{"click":function($event){_vm.showBigQr = true}}}),_vm._v(" "),(_vm.showBigQr)?_c('div',{staticClass:"you-chat-big-qr-container",on:{"click":function($event){_vm.showBigQr = false}}},[_vm._v("\n    X\n    "),_c('div',{staticClass:"you-chat-big-qr",style:({ 'background-image': 'url(' + _vm.chatQr + ')' })})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }