import Vue from "vue";
import VueI18n from "vue-i18n";

const i18n = () =>
    new VueI18n({
        locale: "nl", // default locale
        messages: {
            en: {
                message: {
                    registerNow: "Register now",
                    registerNowBtn: "Register",
                    anonymousRegisterBtn: "Next",
                    alreadyRegisteredLogin: "Already registered?",
                    login: "Log in",
                    loginBtn: "Log in",
                    invalidEmailAddress: "Invalid e-mail address",
                    receivedEmailWithLink:
                        "You have received an e-mail with the link. Click the link to participate.",
                    agreeWithTerms: "Agree with the terms",
                    isRequired: "required",
                    logout: "Logout",
                    youAreAt: "You are at:",
                    sendWhatsappToStudio: "Send a WhatsApp to the studio",
                    unknownResponse: "Unknown response",
                    unknownEmailAddress:
                        "Registration failed: Email address is not known",
                    registrationProcessingError:
                        "Error occured while processing the registration",
                    loginFailed:
                        "Login failed: Email address is not registered",
                    errorProcessingLogin: "Error while processing login",
                    INFORMATION: "INFORMATION",
                    participate: "Participate",
                    typeYourMessageHere: "Type your message here",
                    sendMessage: "Send message",
                    thanksForMessage: "Message sent",
                    thanksForPhoto: "Thanks for sending your photo",
                    sambaIOSErrorTitle: "Not supported on this device",
                    sambaIOSErrorContent:
                        "Open this page on your desktop or laptop to participate in the breakout session. <br /> Unfortunately, your operating system iOS is not yet supported at this time. ",
                    retry: "Retry",
                    typeyourwordhere: "Type your word here",
                    sendWordcloud: "Send words"
                },
            },
            nl: {
                message: {
                    registerNow: "Registreer je nu!",
                    registerNowBtn: "Registreer",
                    anonymousRegisterBtn: "Verder",
                    alreadyRegisteredLogin: "Al geregistreerd?",
                    login: "Log in",
                    loginBtn: "Log in",
                    invalidEmailAddress: "Ongeldig email adres",
                    receivedEmailWithLink:
                        "U heeft een email ontvangen met de login link. Druk op de knop in deze email om deel te nemen.",
                    agreeWithTerms: "U moet akkoord gaan met de voorwaarden",
                    isRequired: "is verplicht",
                    logout: "Logout",
                    youAreAt: "U bevindt zich in:",
                    sendWhatsappToStudio:
                        "Stuur een whatsapp bericht naar de studio",
                    unknownResponse: "Onbekende response",
                    unknownEmailAddress:
                        "Registratie mislukt: Email adres niet bekend",
                    registrationProcessingError:
                        "Fout tijdens verwerken van de registratie",
                    loginFailed:
                        "Inloggen mislukt: Email adres nog niet geregistreerd",
                    errorProcessingLogin: "Fout tijdens inloggen",
                    INFORMATION: "INFORMATIE",
                    participate: "Doe mee",
                    typeYourMessageHere: "Typ uw bericht hier",
                    sendMessage: "Verstuur bericht",
                    thanksForMessage: "Bericht verzonden",
                    thanksForPhoto: "Bedankt voor het sturen van uw foto",
                    sambaIOSErrorTitle: "Niet ondersteund op dit apparaat",
                    sambaIOSErrorContent:
                        "Open deze pagina op uw desktop of laptop om deel te nemen aan de breakout sessie. <br />Helaas wordt op dit moment uw besturingssysteem iOS nog niet ondersteund.",
                    retry: "Probeer opnieuw",
                    typeyourwordhere: "Type hier uw woord",
                    sendWordcloud: "Woorden insturen"
                },
            },
        },
    });

export default i18n;
