<template>
  <div class="podcast-page">
    <podcast-details-overlay
      v-if="overlayEpisode"
      :item="overlayEpisode"
      @back="back"
    />
    <div
      class="podcast-container"
      :style="{
        'grid-template-rows':
          (activePodcast ? 'fit-content(700px)' : '170px') +
          ' auto ' +
          (playerVisible ? '110px' : '0px'),
      }"
      v-if="podcasts.length > 0"
    >
      <div class="podcast-header">
        <main-header v-if="!activePodcast" @logout="logout" />
        <podcast-header
          v-if="activePodcast"
          :podcasts="podcasts"
          :activePodcast="activePodcast"
          @back="back"
        />
      </div>
      <div class="podcast-body" v-if="!activePodcast">
        <podcast-item
          v-for="episode in newEpisodes"
          :item="episode"
          :key="episode.guid"
          type="episode"
          is_new="1"
          @playEpisode="playEpisode"
          @pauseEpisode="pauseEpisode"
          @showOverlay="(episode) => (overlayEpisode = episode)"
          :playingEpisodeGuid="playingEpisodeGuid"
        />
        <podcast-item
          v-for="episode in liveEpisodes"
          :item="episode"
          :key="episode.guid"
          type="episode"
          is_live="1"
          @playEpisode="playEpisode"
          @pauseEpisode="pauseEpisode"
          @showOverlay="(episode) => (overlayEpisode = episode)"
          :playingEpisodeGuid="playingEpisodeGuid"
        />
        <podcast-item
          v-for="podcast in podcasts"
          :item="podcast"
          :key="podcast.guid"
          type="podcast"
          @openPodcast="openPodcast"
        />
      </div>
      <div class="podcast-body" v-if="activePodcast">
        <podcast-item
          v-for="episode in activeEpisodes"
          :item="episode"
          :key="episode.guid"
          type="episode"
          @playEpisode="playEpisode"
          @pauseEpisode="pauseEpisode"
          @showOverlay="(episode) => (overlayEpisode = episode)"
          :playingEpisodeGuid="playingEpisodeGuid"
        />
      </div>
      <div class="podcast-footer">
        <podcast-player
          :slug="slug"
          ref="player"
          @setPlayingEpisodeGuid="setPlayingEpisodeGuid"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import MainHeader from "../components/podcast/Header.vue";
import PodcastHeader from "../components/podcast/PodcastHeader.vue";
import PodcastItem from "../components/podcast/Item.vue";
import PodcastPlayer from "../components/podcast/Player.vue";
import PodcastDetailsOverlay from "../components/podcast/DetailsOverlay.vue";
export default {
  components: {
    MainHeader,
    PodcastHeader,
    PodcastItem,
    PodcastPlayer,
    PodcastDetailsOverlay,
  },
  mixins: [],
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      axios
        .get("/api/frontend/podcast/" + this.slug)
        .then((res) => {
          this.episodes = res.data.episodes.sort((a, b) =>
            a.sorted_at > b.sorted_at ? -1 : 1
          );
          this.podcasts = res.data.podcasts.sort((a, b) =>
            a.sorted_at > b.sorted_at ? -1 : 1
          );
        })
        .catch((e) => {
          if (e.response.status == 401) {
            // Make sure to clear the sso-slug from the events environment
            window.localStorage.removeItem("sso-slug");
            document.cookie = `podcast_token_slug=${this.slug}; path=/`;
            document.location = `/api/sso/${e.response.data.sso_provider}/login`;
          }
        });
    },
    openPodcast(podcast) {
      this.$router.push({
        name: "PodcastEpisode",
        params: { podcastguid: podcast.guid },
      });
    },
    back() {
      if (this.overlayEpisode != null) {
        this.overlayEpisode = null;
      } else {
        this.$router.push({
          name: "Podcast",
        });
      }
    },
    setPlayingEpisodeGuid(episodeGuid) {
      this.playingEpisodeGuid = episodeGuid;
    },
    playEpisode(episode) {
      this.$refs.player.playEpisode(episode);
      this.playerVisible = true;
    },
    pauseEpisode(episode) {
      this.$refs.player.pauseEpisode(episode);
    },
    logout() {
      document.cookie = "podcast_token=; path=/";
      document.location = "/";
    },
  },
  beforeMount() {
    this.slug = this.$route.params.seriesslug;
  },
  data() {
    return {
      slug: null,
      items: [],
      episodes: [],
      podcasts: [],
      overlayEpisode: null,
      playingEpisodeGuid: null,
      playerVisible: false,
    };
  },
  computed: {
    activePodcast() {
      return this.$route.params.podcastguid;
    },
    newEpisodes() {
      return this.episodes.filter((ep) => ep.is_new);
    },
    liveEpisodes() {
      return this.episodes.filter((ep) => ep.livestream_url !== null);
    },
    activeEpisodes() {
      return this.episodes.filter(
        (ep) =>
          ep.podcast_guid == this.activePodcast && ep.livestream_url == null
      );
    },
  },
};
</script>

<style type="text/css">
.podcast-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  background-color: #dddddd;
}
.podcast-container {
  width: 100%;
  max-width: 390px;
  display: grid;
  align-content: stretch;
  background-color: #ffffff;
}
.podcast-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.podcast-body {
  background-color: #eeeeee;
  overflow: auto;
}
.podcast-footer {
  background-color: #eeeeee;
  color: #ffffff;
}
</style>