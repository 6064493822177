<template>
  <div class="container">
    <div
      class="interaction"
      v-if="
        !initializing &&
        !registered &&
        !error &&
        eventInfo &&
        eventInfo.anonymous_register_form_enabled
      "
    >
      <anonymous-registration-form
        :eventInfo="eventInfo"
        :errorMsg="registerErrorMsg"
        @register="
          (fields) =>
            registerAnonymousWatcher($route.params.registertoken, fields)
        "
      />
    </div>
    <div class="interaction" v-if="!initializing && registered">
      <poll-window
        :key="eventDetails.redaction_tool_guid || 'no-redaction-tool'"
        :eventInfo="eventInfo"
        :eventDetails="eventDetails"
        :watcher="watcher"
        :redactionToolGuid="eventDetails.redaction_tool_guid"
        :showMobileFooter="true"
        style="position: absolute"
      />
    </div>
    <div v-if="error">
      Unable to initialize mobile view, please try again at a later time
    </div>
  </div>
</template>

<script>
import WatcherApi from "../mixins/WatcherApi";
import PollWindow from "../components/PollWindow";
import AnonymousRegistrationForm from "../components/AnonymousRegistrationForm.vue";

export default {
  components: {
    PollWindow,
    AnonymousRegistrationForm,
  },
  mixins: [WatcherApi],
  data() {
    return {
      eventInfo: {},
      eventDetails: {},
      watcher: {},
      initializing: true,
      error: false,
      heartbeatInterval: null,
      contentRefreshInterval: null,
      registered: false,
      registerErrorMsg: "",
    };
  },
  mounted() {
    this.initEvent();
    if ("wakeLock" in navigator) {
      try {
        navigator.wakeLock.request("screen");
      } catch (e) {
        console.log("wakelock request failed");
      }
    }
  },
  methods: {
    initEvent() {
      return new Promise((fetchRes) => {
        this.apiGet("info", this.$route.params.slug)
          .then((res) => {
            this.eventInfo = Object.assign(res.data.event_info, {});
            document.title = this.eventInfo.name;
            if (this.eventInfo.language) {
              this.$root.$i18n.locale = this.eventInfo.language;
            }

            let watcherGuid = this.$route.params.watcherguid;
            if (!watcherGuid) {
              watcherGuid = this.getWatcherGuidFromSessionStorage();
            }

            if (watcherGuid) {
              this.initializeWatcher(watcherGuid);
            } else if (this.$route.params.registertoken) {
              if (!this.eventInfo.anonymous_register_form_enabled) {
                this.registerAnonymousWatcher(
                  this.$route.params.registertoken,
                  {}
                );
              } else {
                this.initializing = false;
              }
            }
          })
          .catch((e) => {
            console.log(e);
            this.error = true;
          });
      });
    },
    getWatcherGuidFromSessionStorage() {
      const guid = window.localStorage.getItem(this.eventInfo.slug + "-mguid");
      if (guid) {
        return guid;
      } else {
        return false;
      }
    },
    setWatcherGuidInSessionStorage(guid) {
      window.localStorage.setItem(this.eventInfo.slug + "-mguid", guid);
    },
    removeWatcherGuidFromSessionStorage() {
      window.localStorage.removeItem(this.eventInfo.slug + "-mguid");
    },
    registerAnonymousWatcher: function (registertoken, fields) {
      this.apiPost("watcher/registerAnonymous", { registertoken, fields })
        .then((res) => {
          this.setWatcherGuidInSessionStorage(res.data.guid);
          this.initializeWatcher(res.data.guid);
        })
        .catch((e) => {
          // Failed registering watcher
          console.log(e);
          if (e.response.status == 422) {
            this.registerErrorMsg = e.response.data.message;
          } else {
            this.error = true;
          }
        });
    },
    registerHeartbeatInterval(secInterval) {
      if (secInterval < 10) {
        secInterval = 10;
      }
      if (this.contentRefreshInterval !== secInterval) {
        this.contentRefreshInterval = secInterval;
        if (this.heartbeatInterval) {
          clearInterval(this.heartbeatInterval);
        }
        this.heartbeatInterval = setInterval(
          this.sendHeartbeat,
          secInterval * 1000
        );
      }
    },
    sendHeartbeat: function () {
      this.apiPost("watcher/heartbeat", {})
        .then((res) => {
          this.eventDetails.redaction_tool_guid = res.data.redaction_tool_guid;
          this.registerHeartbeatInterval(res.data.content_refresh_interval);
        })
        .catch((e) => {
          console.log("heartbeat fail");
          if (e && e.response && e.response.status == 401) {
            // Thrown out?
          }
        });
    },
    initializeWatcher: function (guid) {
      this.apiGet("watcher/details", null, guid)
        .then((res) => {
          this.watcher = Object.assign(res.data.watcher, {});
          this.apiGet("details")
            .then((res) => {
              this.eventDetails = Object.assign(res.data.event_details, {});
              this.registerHeartbeatInterval(
                this.eventDetails.content_refresh_interval
              );
              this.registered = true;
              this.initializing = false;
            })
            .catch((e) => {
              console.log(e);
              this.removeWatcherGuidFromSessionStorage();
              this.error = true;
            });
        })
        .catch((e) => {
          this.removeWatcherGuidFromSessionStorage();
          this.error = true;
        });
    },
  },
};
</script>
<style type="text/css">
body,
html {
  font-family: Arial !important;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 150px;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /*centers items on the line (the x-axis by default)*/
  align-items: center;
  overflow: hidden;
}
.interaction {
  width: 286px;
  height: 630px;
  position: relative;
  max-height: 630px;
}
</style>
