<template>
  <div
    class="you"
    :style="
      eventInfo.landing_background_image
        ? {
            'background-image':
              'url(' + eventInfo.landing_background_image + ')',
          }
        : {}
    "
  >
    <div class="you-container" :style="style">
      <div
        class="you-logo"
        :style="
          eventInfo.landing_logo_image
            ? {
                'background-image': 'url(' + eventInfo.landing_logo_image + ')',
              }
            : {}
        "
      ></div>
      <div class="topsection">
        <div
          class="splash-big"
          v-html="eventInfo.splash_text.split('\n').join('<br>')"
        ></div>
        <div class="formarea">
          <div class="you-form-header">{{ $t("message.participate") }}</div>
          <div class="you-form-container">
            <div class="you-form-content">
              <div class="you-errors" v-if="errors.length > 0">
                <div class="you-error" v-for="error in errors" :key="error">
                  {{ error }}
                </div>
                <button
                  v-if="eventInfo.sso_provider != ''"
                  class="you-register-btn"
                  @click="$emit('retrySso')"
                >
                  {{ $t("message.retry") }}
                </button>
              </div>
              <form
                v-if="
                  !finished &&
                  eventInfo.registration_enabled &&
                  eventInfo.sso_provider == ''
                "
                @submit="
                  (e) => {
                    e.preventDefault();
                    register();
                  }
                "
              >
                <div class="you-form-section-header">
                  {{ $t("message.registerNow") }}
                </div>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  v-model="fields.email"
                  v-bind:class="['form-control', 'you-register-input']"
                />
                <div v-for="(field, index) in eventInfo.fields" :key="index">
                  <input
                    type="text"
                    :name="field.name"
                    :id="field.name"
                    :placeholder="field.label"
                    v-model="fields[field.name]"
                    v-bind:class="['form-control', 'you-register-input']"
                  />
                </div>
                <div v-if="eventInfo.agreement_text">
                  <div
                    class="agreement-box"
                    @click="
                      $set(
                        fields,
                        'agreement',
                        fields['agreement'] ? false : true
                      )
                    "
                  >
                    {{ fields["agreement"] ? "x" : "" }}
                  </div>
                  <div class="agreement-label">
                    <ClickableLinks :content="eventInfo.agreement_text" />
                  </div>
                </div>
                <input
                  type="submit"
                  class="you-register-btn"
                  :value="$t('message.registerNowBtn')"
                />
              </form>
              <form
                v-if="
                  !finished &&
                  eventInfo.login_enabled &&
                  eventInfo.sso_provider == ''
                "
                @submit="
                  (e) => {
                    e.preventDefault();
                    login();
                  }
                "
              >
                <div
                  class="you-form-section-header"
                  v-if="eventInfo.registration_enabled"
                >
                  {{ $t("message.alreadyRegisteredLogin") }}
                </div>
                <div
                  class="you-form-section-header"
                  v-if="!eventInfo.registration_enabled"
                >
                  {{ $t("message.login") }}
                </div>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  v-model="fields.login_email"
                  v-bind:class="['form-control', 'you-register-input']"
                />
                <input
                  type="submit"
                  class="you-register-btn"
                  :value="$t('message.loginBtn')"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs" v-if="eventInfo.splash_information">
        <div class="tab">
          {{ $t("message.INFORMATION") }}
        </div>
      </div>
      <div class="tabcontainer" v-if="eventInfo.splash_information">
        <div
          class="tabcontent"
          v-html="eventInfo.splash_information.split('\n').join('<br>')"
        ></div>
      </div>
      <!--<div
        class="you-footer"
        :style="{
          'background-image': eventInfo.footer_image
            ? 'url(' + eventInfo.footer_image + ')'
            : 'url(' + require('../../../img/yoe/footer.png') + ')',
        }"
      ></div>-->
    </div>
  </div>
</template>

<script>
import ClickableLinks from "./ClickableLinks.vue";

export default {
  props: [
    "eventInfo",
    "register",
    "login",
    "fields",
    "errors",
    "loading",
    "finished",
  ],
  components: { ClickableLinks },
  data() {
    return {
      style: {
        "--background_color": this.eventInfo.background_color,
        "--tab1_background_color": this.eventInfo.tab1_background_color,
        "--tab1_text_color": this.eventInfo.tab1_text_color,
        "--tab2_background_color": this.eventInfo.tab2_background_color,
        "--tab2_text_color": this.eventInfo.tab2_text_color,
        "--splash_text_color": this.eventInfo.splash_text_color,
        "--button_color": this.eventInfo.button_color,
        "--button_text_color": this.eventInfo.button_text_color,
        "--button_active_color": this.eventInfo.button_active_color,
        "--button_active_text_color": this.eventInfo.button_active_text_color,
        "--session_block_color": this.eventInfo.session_block_color,
        "--session_block_text_color": this.eventInfo.session_block_text_color,
        "--session_block_active_color":
          this.eventInfo.session_block_active_color,
        "--session_block_active_text_color":
          this.eventInfo.session_block_active_text_color,
        "--registration_text_color": this.eventInfo.registration_text_color,
        "--label_color": this.eventInfo.label_color,
        "--label_text_color": this.eventInfo.label_text_color,
        "--box_background_color": this.eventInfo.box_background_color,
        "--box_input_field_background_color":
          this.eventInfo.box_input_field_background_color,
        "--box_input_field_label_color":
          this.eventInfo.box_input_field_label_color,
        "--box_input_field_text_color":
          this.eventInfo.box_input_field_text_color,
        "--logout_email_text_color": this.eventInfo.logout_email_text_color,
      },
    };
  },
};
</script>

<style type="text/css" scoped>
.you {
  display: inline-block;
  width: 1360px;
  background-repeat: no-repeat;
  background-position-y: 0px;
  background-image: url("../../../img/yoe/background.png");
}
.you-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.you-logo {
  margin: 20px 20px;
  height: 170px;
  width: 278px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 2;
  background-image: url("../../../img/yoe/yoe-logo-big.png");
  cursor: pointer;
}
.topsection {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
}
.splash-big {
  margin-top: 50px;
  font-family: "Gotham Black";
  margin-left: 120px;
  font-size: 100px;
  text-align: left;
  width: 790px;
  line-height: 95px;
  color: var(--splash_text_color);
}
.formarea {
  width: 490px;
}
.you-form-header {
  margin-left: 22px;
  margin-top: 5px;
  padding-top: 2px;
  font-family: "Gotham Bold";
  color: var(--label_text_color);
  font-size: 26px;
  background-color: var(--label_color);
  width: 195px;
  height: 42px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.you-form-container {
  top: 42px;
  background-color: #ffffff;
  border-radius: 25px;
  width: 420px;
  min-height: 50px;
  -webkit-box-shadow: -11px 11px 13px 0px rgba(212, 116, 116, 0.35);
  -moz-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  padding: 10px 35px 20px 35px;
}
.you-errors {
  margin-top: 20px;
}
.you-error {
  font-family: "Gotham Bold";
  font-size: 20px;
  color: var(--button_text_color);
  background-color: var(--button_color);
  min-height: 40px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 4px;
}
.you-register-input {
  background-color: var(--box_input_field_background_color);
  border: none;
  border-radius: 15px;
  font-family: "Gotham Bold";
  color: var(--box_input_field_text_color);
  font-size: 22px;
  margin-bottom: 12px;
}
.you-register-input::placeholder {
  color: red;
  color: var(--box_input_field_label_color);
}
.agreement-box {
  float: left;
  background-color: var(--box_input_field_background_color);
  border: none;
  border-radius: 10px;
  color: var(--box_input_field_text_color);
  width: 40px;
  height: 40px;
  margin-right: 10px;
  text-align: center;
  font-size: 24px;
  font-family: "Gotham Bold";
  cursor: pointer;
}

.agreement-label {
  color: var(--registration_text_color);
  font-family: "Gotham Book";
  font-size: 20px;
  text-align: left;
  margin-bottom: 8px;
}
.you-form-section-header {
  font-family: "Gotham Bold";
  color: #000000;
  font-size: 25px;
  padding: 10px 10px 5px 10px;
  text-align: left;
}
.you-register-btn {
  background-color: var(--button_color);
  border: none;
  border-radius: 10px;
  font-family: "Gotham Bold";
  color: var(--button_text_color);
  font-size: 22px;
  padding: 5px 10px 5px 10px;
  width: 100%;
}
.tabs {
  text-align: left;
  padding-left: 20px;
  top: 820px;
}

.tab {
  font-family: "Gotham Bold";
  font-size: 18px;
  color: var(--tab2_text_color);
  display: inline-block;
  padding: 2px 20px 2px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 6px;
  cursor: pointer;
  background-color: var(--tab2_background_color);
}

.tabcontent {
  font-family: "Gotham Book";
  text-align: left;
  min-height: 25px;
  padding: 40px 40px 40px 40px;
  background-color: var(--tab2_background_color);
  color: var(--tab2_text_color);
  font-size: 20px;
}

.you-footer {
  width: 1360px;
  height: 109px;
}
</style>
