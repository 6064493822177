var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tracks"},[_c('div',{staticClass:"indicator-container"},[_c('div',{staticClass:"indicator-area-container"},[_c('div',{staticClass:"indicator-area"},[_c('div',{staticClass:"indicator",style:(_vm.indicatorCss())}),_vm._v(" "),_c('div',{staticClass:"start-indicator"}),_vm._v(" "),_c('div',{staticClass:"end-indicator"})])]),_vm._v(" "),_c('div',{staticClass:"track-container"},_vm._l((_vm.tracksWithSessions),function(track){return _c('div',{key:track.name,staticClass:"track"},[_c('div',{staticClass:"track-title"},[_vm._v(_vm._s(track.name))]),_vm._v(" "),_c('div',{staticClass:"track-timeline"},[_c('div',{staticClass:"session-container"},_vm._l((track.sessions),function(session){return _c('div',{key:session.guid,class:[
                  'session',
                  _vm.activeSession && session.guid === _vm.activeSession.guid
                    ? 'session-active'
                    : null,
                ],style:('left: ' +
                  _vm.sessionStartPercentage(session) +
                  '; width: ' +
                  _vm.sessionWidthPercentage(session) +
                  ';'),on:{"click":function($event){return _vm.$emit('playsession', session)},"mousemove":(e) => _vm.showDetails(e, session),"mouseout":_vm.hideDetails}},[_c('div',{staticClass:"session-info"},[(session.show_time_in_timetable)?_c('div',{staticClass:"session-time"},[_vm._v("\n                    "+_vm._s(_vm._f("moment")(session.start_time,"HH:mm"))+" -\n                    "+_vm._s(_vm._f("moment")(session.end_time,"HH:mm"))+"\n                  ")]):_vm._e(),_vm._v(" "),(session.show_name_in_timetable)?_c('div',{staticClass:"session-title"},[_vm._v("\n                    "+_vm._s(session.name)+"\n                  ")]):_vm._e()])])}),0)])])}),0)])]),_vm._v(" "),(_vm.detailSession !== null)?_c('div',{ref:"detailsDiv",staticClass:"session-details",style:('left: ' + _vm.detailsDivX + 'px; top: ' + _vm.detailsDivY + 'px;')},[_c('div',{staticClass:"session-details-name"},[_vm._v(_vm._s(_vm.detailSession.name))]),_vm._v(" "),_c('div',{staticClass:"session-details-speaker"},[_vm._v(_vm._s(_vm.detailSession.speaker))]),_vm._v(" "),_c('div',{staticClass:"session-details-description"},[_vm._v("\n      "+_vm._s(_vm.detailSession.description)+"\n    ")]),_vm._v(" "),(_vm.detailSession.image)?_c('div',{staticClass:"session-details-image",style:({ 'background-image': 'url(' + _vm.detailSession.image + ')' })}):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }