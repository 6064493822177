<template>
  <div />
</template>
<script>
import axios from "axios";
import uaParser from "ua-parser-js";
const ipApiKey = "GV9bQj3EiBfByLv";

export default {
  data() {
    return {
      country: null,
      activeEpisodeGuid: null,
      activeEpisodeGuidPending: false,
      activePlayGuid: null,
      lastPlayingPosition: null,
    };
  },
  mounted() {},
  props: ["slug"],
  methods: {
    updateState(playingEpisodeGuid, playingPosition) {
      if (playingEpisodeGuid !== this.activeEpisodeGuid) {
        this.activePlayGuid = null;
        this.activeEpisodeGuid = playingEpisodeGuid;
        this.lastPlayingPosition = null;
      }
      if (!this.activePlayGuid) {
        this.registerPlay()
          .then(() => {
            this.registerHeartbeat(playingPosition);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.registerHeartbeat(playingPosition);
      }
    },
    getCountry() {
      return new Promise((resolve, rej) => {
        if (this.country !== null) {
          return resolve(this.country);
        }
        axios
          .get(`https://pro.ip-api.com/json/?fields=country&key=${ipApiKey}`)
          .then((res) => {
            this.country = res.data.country;
            resolve(this.country);
          })
          .catch((err) => {
            console.log({ err });
            this.country = "";
            resolve(this.country);
          });
      });
    },
    registerPlay() {
      return new Promise((resolve, rej) => {
        if (this.activeEpisodeGuidPending) {
          return rej("Pending registration");
        }
        this.activeEpisodeGuidPending = true;
        const uaData = new uaParser().getResult();
        this.getCountry().then((country) => {
          axios
            .post(
              `/api/frontend/podcast/${this.slug}/analytics/play/${this.activeEpisodeGuid}`,
              {
                country,
                os: uaData.os.name,
              }
            )
            .then((res) => {
              this.activeEpisodeGuidPending = false;
              this.activePlayGuid = res.data.guid;
              resolve();
            })
            .catch((err) => {
              // Delay retry to 5 secs
              setTimeout(() => {
                this.activeEpisodeGuidPending = false;
              }, 5000);
            });
        });
      });
    },
    registerHeartbeat(playingPosition) {
      const roundedPosition = parseInt(Math.floor(playingPosition / 30) * 30);
      if (
        this.lastPlayingPosition === null ||
        this.lastPlayingPosition !== roundedPosition
      ) {
        this.lastPlayingPosition = roundedPosition;
        axios
          .post(
            `/api/frontend/podcast/${this.slug}/analytics/heartbeat/${this.activePlayGuid}`,
            {
              position: roundedPosition,
            }
          )
          .then((res) => {})
          .catch((e) => {
            // Delay retry to 5 secs
            setTimeout(() => {
              this.lastPlayingPosition = null;
            }, 5000);
          });
      }
    },
  },
};
</script>