<template>
  <div>
    <div class="you-chat-header">Event Chat</div>
    <div class="you-chat-container">
      <div class="you-chat-content" ref="chatcontent">
        <div
          :class="[
            'you-chat-message',
            chatmsg.type == 'whatsapp'
              ? 'you-chat-message-green'
              : 'you-chat-message-blue',
          ]"
          v-for="chatmsg in chat"
          :key="chatmsg.id"
        >
          {{ chatmsg.type == "whatsapp" ? chatmsg.message : "" }}
          <ClickableLinks
            v-if="chatmsg.type != 'whatsapp'"
            :content="chatmsg.message"
          />
        </div>
      </div>
    </div>
    <div
      class="you-chat-promo"
      :style="{
        'background-image': eventInfo.page_qr_background_image
          ? 'url(' + eventInfo.page_qr_background_image + ')'
          : 'url(' + require('../../../img/yoe/scan-qr.png') + ')',
      }"
    ></div>
    <div
      class="you-chat-qr"
      :style="{ 'background-image': 'url(' + chatQr + ')' }"
      @click="showBigQr = true"
    ></div>
    <div
      class="you-chat-big-qr-container"
      v-if="showBigQr"
      @click="showBigQr = false"
    >
      X
      <div
        class="you-chat-big-qr"
        :style="{ 'background-image': 'url(' + chatQr + ')' }"
      ></div>
    </div>
  </div>
</template>
<script>
import WatcherApi from "../mixins/WatcherApi";
import _ from "lodash";
import ClickableLinks from "./ClickableLinks.vue";
export default {
  props: ["eventInfo", "eventDetails", "activeSession", "watcher"],
  components: { ClickableLinks },
  mixins: [WatcherApi],
  data() {
    return {
      chat: [
        /*
        {
          id: "asfdasdf",
          message:
            "Aenean sed lorem non metus congue feugiat. Vestibulum pulvinar a ante non porta. Ut malesuada in velit sed pretium.",
          date: "2020-08-11T12:30:52.438Z",
          type: "whatsapp",
        },
        */
      ],
      pollInterval: null,
      showBigQr: false,
    };
  },
  computed: {
    chatQr() {
      if (this.activeSession) {
        return this.activeSession.chat_qr;
      } else {
        return this.eventDetails.chat_qr;
      }
    },
  },
  mounted() {
    this.pollServer();
    this.pollInterval = setInterval(this.pollServer, 10000);
  },
  beforeDestroy() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  },
  methods: {
    pollServer() {
      const url = this.activeSession
        ? "sessions/" + this.activeSession.guid + "/chat/messages"
        : "chat/messages";
      this.apiGet(url).then((res) => {
        if (!res.data.messages) {
          return;
        }
        const prev = JSON.stringify(this.chat);
        // Add new messages or overwrite on edit
        res.data.messages.forEach((newMsg) => {
          const existingIndex = this.chat.findIndex(
            (msg) => msg.id == newMsg.id
          );
          if (existingIndex > -1) {
            this.chat[existingIndex] = newMsg;
          } else {
            this.chat.push(newMsg);
          }
        });
        // Sort chat by date
        this.chat = _.sortBy(this.chat, ["date"]);

        // Delete removed id's
        res.data.internal.forEach((removedId) => {
          const indexToRemove = this.chat.findIndex(
            (msg) => msg.id == removedId
          );
          if (indexToRemove > -1) {
            this.chat.splice(indexToRemove, 1);
          }
        });

        // Scroll to bottom on change
        if (prev !== JSON.stringify(this.chat)) {
          this.$nextTick(() => this.scrollToEnd());
        }
      });
    },
    scrollToEnd: function () {
      this.$refs.chatcontent.scrollTop =
        this.$refs.chatcontent.lastElementChild.offsetTop;
    },
  },
};
</script>
<style type="text/css" scoped>
.you-chat-header {
  margin-left: 50px;
  margin-top: 5px;
  padding-top: 2px;
  font-family: "Gotham Bold";
  color: var(--label_text_color);
  font-size: 24px;
  background-color: var(--label_color);
  width: 195px;
  height: 45px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.you-chat-container {
  position: absolute;
  top: 45px;
  background-color: #dddddd;
  border-radius: 7px;
  width: 286px;
  height: 432px;
  -webkit-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
}
.you-chat-promo {
  position: absolute;
  top: 383px;
  left: 9px;
  width: 264px;
  height: 87px;
  background-repeat: no-repeat;
}

.you-chat-qr {
  position: absolute;
  top: 388px;
  left: 168px;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.you-chat-big-qr-container {
  cursor: pointer;
  position: absolute;
  top: 66px;
  left: 17px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  margin-top: 5px;
  text-align: right;
  font-family: "Gotham Bold";
  font-size: 20px;
  color: white;
  padding-top: 5px;
  padding-right: 5px;
}

.you-chat-big-qr {
  margin: 10px 5px 10px 10px;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
}

.you-chat-content {
  margin: 11px;
  width: 264px;
  height: 340px;
  background-color: var(--box_background_color);
  padding: 15px 10px 15px 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.you-chat-message {
  font-family: "Gotham Book";
  text-align: left;
  font-size: 12px;
  width: 185px;
  padding: 0px 10px 10px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.you-chat-message-green {
  margin-left: 45px;
  background-color: #5ed168;
}

.you-chat-message-blue {
  margin-left: 5px;
  background-color: #3093b2;
}

.you-chat-message:last-of-type {
  margin-bottom: 20px;
}

.you-chat-message-green::before {
  height: 8px;
  display: block;
  width: 178px;
  content: "";
  background-image: url("../../../img/yoe/green-tick.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.you-chat-message-blue::before {
  height: 8px;
  display: block;
  position: relative;
  left: -13px;
  content: "";
  background-image: url("../../../img/yoe/blue-tick.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}
</style>
<style>
.you-chat-message a {
  color: black;
  text-decoration: underline;
}
</style>
