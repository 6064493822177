<template>
  <div class="playercontainer" v-if="episode">
    <player-analytics :slug="slug" ref="analytics" />
    <div class="contenttitle">{{ episode.name }}</div>
    <div class="infocontainer">
      <div
        class="icon"
        :style="{
          'background-image': episode.image
            ? 'url(' + episode.image + ')'
            : null,
        }"
      />
      <div class="buttons">
        <div v-if="!isLiveStream" class="button skipback" @click="skipback" />
        <div v-if="!playing" class="button playerplay" @click="start" />
        <div v-if="playing" class="button playerpause" @click="pause" />
        <div
          v-if="!isLiveStream"
          class="button skipforward"
          @click="skipforward"
        />
      </div>
    </div>
    <div class="playerprogresscontainer">
      <div class="playerprogress" :style="{ width: percentage }" />
    </div>
    <audio ref="audiotag" :key="episode.guid">
      <source :key="episode.guid" :src="mp3Path" type="audio/mpeg" />
    </audio>
  </div>
</template>
<script>
import PlayerAnalytics from "./PlayerAnalytics.vue";
export default {
  components: { PlayerAnalytics },
  props: ["slug"],
  data() {
    return {
      playing: false,
      episode: null,
      currentTime: 0,
      playtrigger: false,
      initialFavicon: "/icon/favicon.gif",
    };
  },
  mounted() {
    setInterval(() => {
      const prev = this.playing;
      if (!this.$refs.audiotag || !this.$refs.audiotag.duration) {
        this.playing = false;
      } else {
        this.playing = !this.$refs.audiotag.paused;
      }
      this.$emit(
        "setPlayingEpisodeGuid",
        this.playing ? this.episode.guid : null
      );
      if (this.playing) {
        this.$refs.analytics.updateState(
          this.episode.guid,
          this.$refs.audiotag.currentTime
        );
      }
    }, 100);
  },
  computed: {
    isLiveStream() {
      return (
        this.episode && ![null, undefined].includes(this.episode.livestream_url)
      );
    },
    mp3Path() {
      if (this.isLiveStream) {
        return this.episode.livestream_url;
      } else {
        return `/api/frontend/podcast/${this.slug}/listen/${this.episode.guid}`;
      }
    },
    percentage() {
      const dummy = this.currentTime;
      if (!this.$refs.audiotag || !this.$refs.audiotag.duration) {
        return "0%";
      }
      return (this.currentTime / this.$refs.audiotag.duration) * 100 + "%";
    },
  },
  watch: {
    playing() {
      if (!this.playing) {
        document.title = "Vanderlande Podcast Player";
        this.setFavicon(this.initialFavicon);
      } else {
        document.title = this.episode.name;
        this.setFavicon(this.episode.image);
      }
    },
  },
  methods: {
    playEpisode(episode) {
      this.$set(this, "episode", episode);
      this.$nextTick(() => {
        this.$refs.audiotag.addEventListener("timeupdate", () => {
          this.currentTime = this.$refs.audiotag.currentTime.toFixed();
        });
        this.start();
      });
    },
    pauseEpisode(episode) {
      this.pause();
    },
    start() {
      this.$refs.audiotag.play();
      this.playtrigger = true;
    },
    pause() {
      this.$refs.audiotag.pause();
      this.playtrigger = false;
    },
    skipback() {
      this.$refs.audiotag.currentTime -= 15.0;
    },
    skipforward() {
      this.$refs.audiotag.currentTime += 15.0;
    },
    setFavicon(icon) {
      let oldFavicon = document.getElementById("favicon");
      var link = document.createElement("link");
      link.id = "favicon";
      link.type = "image/x-icon";
      link.rel = "icon";
      link.href = icon;
      if (oldFavicon) {
        document.head.removeChild(oldFavicon);
      }
      document.head.appendChild(link);
    },
  },
};
</script>

<style scoped>
.playercontainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: #333333;
}
.contenttitle {
  margin: 0px 10px;
  font-family: "MetaPro-Bold";
  font-size: 15px;
  color: #ffffff;
  overflow: hidden;
  height: 31px;
  line-height: 31px;
}
.infocontainer {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 10px;
  height: 63px;
}
.icon {
  min-width: 63px;
  height: 63px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ef7b21;
}
.buttons {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  height: 63px;
  justify-content: space-evenly;
  align-items: center;
}
.button {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  background-size: contain;
  height: 50px;
  cursor: pointer;
}
.skipback {
  background-image: url("../../../../img/podcast/vdlande/skipback.svg");
}
.playerplay {
  background-image: url("../../../../img/podcast/vdlande/playerplay.svg");
}
.playerpause {
  background-image: url("../../../../img/podcast/vdlande/playerpause.svg");
}
.skipforward {
  background-image: url("../../../../img/podcast/vdlande/skipforward.svg");
}
.playerprogresscontainer {
  margin-top: 12px;
  height: 5px;
  background-color: #8f603c;
}
.playerprogress {
  height: 5px;
  background-color: #ef7b21;
}
</style>