<template>
  <div />
</template>
<script>
import Echo from "laravel-echo";

export default {
  props: ["eventInfo", "redactionToolGuid", "watcher"],
  mounted() {
    this.Echo = new Echo({
      broadcaster: "pusher",
      key: "livesom-dev-key",
      wsHost: this.eventInfo.websocket_host
        ? this.eventInfo.websocket_host
        : window.location.hostname,
      wsPort: window.location.port,
      forceTLS: false,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      authEndpoint: "/api/broadcasting/auth",
      auth: {
        headers: {
          Authorization: "watcher " + this.watcher.guid,
          "X-XSRF-TOKEN": this.getCookie("XSRF-TOKEN"),
        },
      },
    });
    this.Echo.private(
      "announcements." + this.eventInfo.slug + "." + this.redactionToolGuid
    ).listen(".interaction.liveitemchanged", (payload) => {
      this.$emit("liveitemchanged", payload);
    });
    this.Echo.private(
      "watcher." +
        this.eventInfo.slug +
        "." +
        this.redactionToolGuid +
        "." +
        this.watcher.guid
    )
      .listen(".interaction.pollanswered", (payload) => {
        this.$emit("pollanswered", payload);
      })
      .listen(".interaction.wordcloudanswered", (payload) => {
        this.$emit("wordcloudanswered", payload);
      });
  },
  beforeDestroy() {
    if (this.Echo.connector.pusher) {
      this.Echo.connector.pusher.disconnect();
    }
  },
  methods: {
    getCookie(name) {
      if (!document.cookie) {
        return null;
      }

      const xsrfCookies = document.cookie
        .split(";")
        .map((c) => c.trim())
        .filter((c) => c.startsWith(name + "="));

      if (xsrfCookies.length === 0) {
        return null;
      }
      return decodeURIComponent(xsrfCookies[0].split("=")[1]);
    },
  },
};
</script>
