<template>
  <div style="height: 100%">
    <div v-if="isIos()" class="warning">
      <div class="warningheader">{{ $t("message.sambaIOSErrorTitle") }}</div>
      <div
        class="warningcontent"
        v-html="$t('message.sambaIOSErrorContent')"
      ></div>
    </div>
    <iframe
      v-if="iframeSrc && !isIos()"
      :src="iframeSrc"
      width="100%"
      height="100%"
      allow="microphone; camera; fullscreen; display-capture"
    ></iframe>
  </div>
</template>
<script>
import WatcherApi from "../mixins/WatcherApi";

export default {
  props: ["eventInfo", "eventDetails", "activeSession", "watcher"],
  mixins: [WatcherApi],
  data() {
    return {
      iframeSrc: null,
    };
  },
  created() {},
  mounted() {
    this.apiPost(
      "samba/generate_link",
      this.activeSession ? { session: this.activeSession.guid } : {}
    ).then((res) => {
      this.iframeSrc = res.data.personal_session_link;
    });
  },
  methods: {
    isIos() {
      return this.$device.ios;
    },
  },
};
</script>
<style type="text/css" scoped>
.warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.warningheader {
  font-family: "Gotham Bold";
  font-size: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.warningcontent {
  font-family: "Gotham Book";
  font-size: 16px;
  color: var(--label_color);
}
</style>
