<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  mounted() {
    const slug = window.localStorage.getItem("sso-slug");
    if (slug) {
      window.location.href = `/${slug}?ssotoken=${this.$route.query.ssotoken}`;
    }
  },
};
</script>