<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
</style>
<style lang="scss">
// Import Main styles for this application
@import "../scss/style";
</style>
