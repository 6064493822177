var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"you",style:(_vm.eventInfo.landing_background_image
      ? {
          'background-image':
            'url(' + _vm.eventInfo.landing_background_image + ')',
        }
      : {})},[_c('div',{staticClass:"you-container",style:(_vm.style)},[_c('div',{staticClass:"you-logo",style:(_vm.eventInfo.landing_logo_image
          ? {
              'background-image': 'url(' + _vm.eventInfo.landing_logo_image + ')',
            }
          : {})}),_vm._v(" "),_c('div',{staticClass:"topsection"},[_c('div',{staticClass:"splash-big",domProps:{"innerHTML":_vm._s(_vm.eventInfo.splash_text.split('\n').join('<br>'))}}),_vm._v(" "),_c('div',{staticClass:"formarea"},[_c('div',{staticClass:"you-form-header"},[_vm._v(_vm._s(_vm.$t("message.participate")))]),_vm._v(" "),_c('div',{staticClass:"you-form-container"},[_c('div',{staticClass:"you-form-content"},[(_vm.errors.length > 0)?_c('div',{staticClass:"you-errors"},[_vm._l((_vm.errors),function(error){return _c('div',{key:error,staticClass:"you-error"},[_vm._v("\n                "+_vm._s(error)+"\n              ")])}),_vm._v(" "),(_vm.eventInfo.sso_provider != '')?_c('button',{staticClass:"you-register-btn",on:{"click":function($event){return _vm.$emit('retrySso')}}},[_vm._v("\n                "+_vm._s(_vm.$t("message.retry"))+"\n              ")]):_vm._e()],2):_vm._e(),_vm._v(" "),(
                !_vm.finished &&
                _vm.eventInfo.registration_enabled &&
                _vm.eventInfo.sso_provider == ''
              )?_c('form',{on:{"submit":(e) => {
                  e.preventDefault();
                  _vm.register();
                }}},[_c('div',{staticClass:"you-form-section-header"},[_vm._v("\n                "+_vm._s(_vm.$t("message.registerNow"))+"\n              ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.email),expression:"fields.email"}],class:['form-control', 'you-register-input'],attrs:{"type":"text","name":"email","id":"email","placeholder":"Email"},domProps:{"value":(_vm.fields.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fields, "email", $event.target.value)}}}),_vm._v(" "),_vm._l((_vm.eventInfo.fields),function(field,index){return _c('div',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields[field.name]),expression:"fields[field.name]"}],class:['form-control', 'you-register-input'],attrs:{"type":"text","name":field.name,"id":field.name,"placeholder":field.label},domProps:{"value":(_vm.fields[field.name])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fields, field.name, $event.target.value)}}})])}),_vm._v(" "),(_vm.eventInfo.agreement_text)?_c('div',[_c('div',{staticClass:"agreement-box",on:{"click":function($event){return _vm.$set(
                      _vm.fields,
                      'agreement',
                      _vm.fields['agreement'] ? false : true
                    )}}},[_vm._v("\n                  "+_vm._s(_vm.fields["agreement"] ? "x" : "")+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"agreement-label"},[_c('ClickableLinks',{attrs:{"content":_vm.eventInfo.agreement_text}})],1)]):_vm._e(),_vm._v(" "),_c('input',{staticClass:"you-register-btn",attrs:{"type":"submit"},domProps:{"value":_vm.$t('message.registerNowBtn')}})],2):_vm._e(),_vm._v(" "),(
                !_vm.finished &&
                _vm.eventInfo.login_enabled &&
                _vm.eventInfo.sso_provider == ''
              )?_c('form',{on:{"submit":(e) => {
                  e.preventDefault();
                  _vm.login();
                }}},[(_vm.eventInfo.registration_enabled)?_c('div',{staticClass:"you-form-section-header"},[_vm._v("\n                "+_vm._s(_vm.$t("message.alreadyRegisteredLogin"))+"\n              ")]):_vm._e(),_vm._v(" "),(!_vm.eventInfo.registration_enabled)?_c('div',{staticClass:"you-form-section-header"},[_vm._v("\n                "+_vm._s(_vm.$t("message.login"))+"\n              ")]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.login_email),expression:"fields.login_email"}],class:['form-control', 'you-register-input'],attrs:{"type":"text","name":"email","id":"email","placeholder":"Email"},domProps:{"value":(_vm.fields.login_email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fields, "login_email", $event.target.value)}}}),_vm._v(" "),_c('input',{staticClass:"you-register-btn",attrs:{"type":"submit"},domProps:{"value":_vm.$t('message.loginBtn')}})]):_vm._e()])])])]),_vm._v(" "),(_vm.eventInfo.splash_information)?_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab"},[_vm._v("\n        "+_vm._s(_vm.$t("message.INFORMATION"))+"\n      ")])]):_vm._e(),_vm._v(" "),(_vm.eventInfo.splash_information)?_c('div',{staticClass:"tabcontainer"},[_c('div',{staticClass:"tabcontent",domProps:{"innerHTML":_vm._s(_vm.eventInfo.splash_information.split('\n').join('<br>'))}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }