import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=4764dc97&scoped=true&"
var script = {}
import style0 from "./Header.vue?vue&type=style&index=0&id=4764dc97&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4764dc97",
  null
  
)

export default component.exports