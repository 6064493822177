var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"},style:(_vm.style)},[_c('div',{staticClass:"you-poll-header"},[_vm._v(_vm._s(_vm.$t("message.participate")))]),_vm._v(" "),_c('div',{staticClass:"you-poll-container"},[(_vm.activePoll)?_c('div',{key:_vm.activePoll.guid,staticClass:"you-poll-content",style:(_vm.containerStyle)},[_c('div',{staticClass:"elemcontainer"},[_c('div',{staticClass:"question",style:(_vm.questionContentStyle)},[_vm._v("\n          "+_vm._s(_vm.activePoll.question)+"\n        ")])]),_vm._v(" "),_vm._l((['A', 'B', 'C', 'D', 'E', 'F']),function(answer,index){return _c('div',{key:index,staticClass:"elemcontainer btn"},[(_vm.activePoll.answers[index])?_c('div',{class:[
            'answer',
            _vm.givenPollAnswers[_vm.activePoll.guid] == answer
              ? 'givenanswer'
              : null,
            _vm.moreThenFourAnswers ? 'answer-morethenfour' : null,
          ],on:{"click":function($event){return _vm.submitPoll(answer)}}},[_c('div',{staticClass:"answerstart"},[_vm._v(_vm._s(answer))]),_vm._v(" "),_c('div',{staticClass:"answercontent",style:(_vm.answerContentStyle)},[_vm._v("\n            "+_vm._s(_vm.activePoll.answers[index])+"\n          ")])]):_vm._e()])})],2):_vm._e(),_vm._v(" "),(_vm.activeWordcloud)?_c('div',{key:_vm.activeWordcloud.guid,staticClass:"you-wordcloud-content",style:(_vm.containerStyle)},[_c('div',{staticClass:"elemcontainer"},[_c('div',{staticClass:"question"},[_vm._v("\n          "+_vm._s(_vm.activeWordcloud.question)+"\n        ")])]),_vm._v(" "),_c('div',_vm._l((_vm.activeWordcloud.answer_count),function(wordCloudAnswerNumber){return _c('div',{key:wordCloudAnswerNumber,staticClass:"wordcloud-row"},[_c('label',{staticClass:"wordcloud-label"},[_vm._v(_vm._s(wordCloudAnswerNumber)+".")]),_vm._v(" "),(!_vm.givenWordcloudAnswers[_vm.activeWordcloud.guid])?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wordcloudResponse[wordCloudAnswerNumber - 1]),expression:"wordcloudResponse[wordCloudAnswerNumber - 1]"}],class:[
              'form-control',
              'wordcloud-input',
              _vm.wordcloudErrors.includes(wordCloudAnswerNumber - 1)
                ? 'wordcloud-error'
                : null,
            ],attrs:{"type":"text","placeholder":_vm.$t('message.typeyourwordhere'),"maxlength":25},domProps:{"value":(_vm.wordcloudResponse[wordCloudAnswerNumber - 1])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.wordcloudResponse, wordCloudAnswerNumber - 1, $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.givenWordcloudAnswers[_vm.activeWordcloud.guid][
                wordCloudAnswerNumber - 1
              ]
            ),expression:"\n              givenWordcloudAnswers[activeWordcloud.guid][\n                wordCloudAnswerNumber - 1\n              ]\n            "}],class:['form-control', 'wordcloud-input'],attrs:{"type":"text","disabled":true},domProps:{"value":(
              _vm.givenWordcloudAnswers[_vm.activeWordcloud.guid][
                wordCloudAnswerNumber - 1
              ]
            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.givenWordcloudAnswers[_vm.activeWordcloud.guid], 
                wordCloudAnswerNumber - 1
              , $event.target.value)}}})])}),0),_vm._v(" "),_c('div',{staticClass:"submit-container"},[(
            !_vm.wordcloudSending && !_vm.givenWordcloudAnswers[_vm.activeWordcloud.guid]
          )?_c('button',{staticClass:"btn btn-message-submit",on:{"click":_vm.submitWordcloud}},[_vm._v("\n          "+_vm._s(_vm.$t("message.sendWordcloud"))+"\n        ")]):_vm._e()])]):_vm._e(),_vm._v(" "),(!_vm.activePoll && !_vm.activeWordcloud)?_c('div',{staticClass:"you-poll-content",style:(_vm.containerStyle)},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageContent),expression:"messageContent"}],staticClass:"form-control message-input",attrs:{"maxlength":800,"placeholder":_vm.$t('message.typeYourMessageHere')},domProps:{"value":(_vm.messageContent)},on:{"input":function($event){if($event.target.composing)return;_vm.messageContent=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"submit-container"},[(_vm.confirmationOverlayMessage)?_c('div',{staticClass:"submit-confirmation-overlay",on:{"click":function($event){_vm.confirmationOverlayMessage = null}}},[_c('div',{staticClass:"submit-confirmation-overlay-content"},[_c('div',{staticClass:"submit-confirmation-overlay-content-close"},[_vm._v("X")]),_vm._v("\n            "+_vm._s(_vm.confirmationOverlayMessage)+"\n          ")])]):_vm._e(),_vm._v(" "),(!_vm.messageSending && !_vm.thankyou)?_c('button',{staticClass:"btn btn-message-submit",on:{"click":_vm.submitMessage}},[_vm._v("\n          "+_vm._s(_vm.$t("message.sendMessage"))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.thankyou || _vm.messageSending)?_c('div',{staticClass:"btn btn-message-submit btn-message-thankyou"},[_vm._v("\n          "+_vm._s(_vm.$t("message.thanksForMessage"))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.$device.ios || _vm.$device.android)?_c('div',{staticClass:"picture-container"},[_c('photo-uploader',{attrs:{"eventInfo":_vm.eventInfo,"redactionToolGuid":_vm.redactionToolGuid,"watcher":_vm.watcher},on:{"uploadSuccess":function($event){_vm.confirmationOverlayMessage = _vm.$t('message.thanksForPhoto')}}})],1):_vm._e()])]):_vm._e()]),_vm._v(" "),(_vm.showQr)?_c('div',{staticClass:"you-poll-promo",style:({
      'background-image': _vm.eventInfo.page_interaction_qr_background_image
        ? 'url(' + _vm.eventInfo.page_interaction_qr_background_image + ')'
        : 'url(' + require('../../../img/yoe/scan-qr-interaction.png') + ')',
    })}):_vm._e(),_vm._v(" "),(_vm.showMobileFooter && _vm.eventInfo.mobile_footer_image)?_c('div',{staticClass:"you-mobile-footer-image",style:({
      'background-image': 'url(' + _vm.eventInfo.mobile_footer_image + ')',
    }),on:{"click":_vm.openMobileFooterLink}}):_vm._e(),_vm._v(" "),(_vm.showQr)?_c('div',{staticClass:"you-poll-qr",on:{"click":function($event){_vm.showBigQr = true}}},[(_vm.showQr)?_c('vue-qrcode',{staticClass:"you-poll-small-qr",attrs:{"value":_vm.qrUrl,"margin":1}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showBigQr)?_c('div',{staticClass:"you-poll-big-qr-container",on:{"click":function($event){_vm.showBigQr = false}}},[_vm._v("\n    X\n    "),_c('vue-qrcode',{staticClass:"you-poll-big-qr",attrs:{"value":_vm.qrUrl}})],1):_vm._e(),_vm._v(" "),_c('websocket-client',{attrs:{"eventInfo":_vm.eventInfo,"redactionToolGuid":_vm.redactionToolGuid,"watcher":_vm.watcher},on:{"liveitemchanged":_vm.handleLiveItemChanged,"pollanswered":_vm.handlePollAnswered,"wordcloudanswered":_vm.handleWordcloudAnswered}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }