<template>
  <div>
    <div class="toprow">
      <div class="topleft"></div>
      <div class="topcenter"></div>
      <div class="topright">
        <div class="eventname">{{ eventInfo.name }}</div>
        <div class="whatsappnr" v-if="eventDetails.whatsapp_number">
          {{ $t("message.sendWhatsappToStudio") }} :
          {{ eventDetails.whatsapp_number }}
        </div>
      </div>
    </div>
    <div class="bottomrow">
      <div class="bottomleft">
        <div
          class="eventlogo"
          :style="{ 'background-image': 'url(' + eventInfo.splash_logo + ')' }"
        ></div>
      </div>
      <div class="bottomright">
        <div
          class="confirmationtext"
          v-html="eventDetails.confirmation_text.split('\n').join('<br>')"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
const moment = require("moment");
export default {
  props: ["eventInfo", "eventDetails"],
  data() {
    return {};
  },
};
</script>
<style type="text/css" scoped>
.toprow {
  height: 80px;
  width: 100%;
  margin-top: 20px;
  display: table;
}
.topleft {
  display: table-cell;
  width: 160px;
}
.topcenter {
  display: table-cell;
  width: 40px;
  border-bottom: 4px solid var(--tab2_text_color);
}
.topright {
  display: table-cell;
  border-bottom: 4px solid var(--tab2_text_color);
  border-left: 4px solid var(--tab2_text_color);
  padding: 15px 20px 0px 20px;
}
.bottomrow {
  min-height: 160px;
  width: 100%;
  display: table;
}
.bottomleft {
  display: table-cell;
  width: 200px;
}
.bottomright {
  display: table-cell;
  border-left: 4px solid var(--tab2_text_color);
  vertical-align: top;
  padding: 20px 20px 10px 20px;
}
.eventname {
  text-align: left;
  font-family: "Gotham Bold";
  color: var(--tab2_text_color);
  font-size: 26px;
  margin-bottom: 5px;
}
.whatsappnr {
  text-align: left;
  font-family: "Gotham Bold";
  color: var(--tab2_text_color);
  font-size: 16px;
  margin-bottom: 10px;
}
.eventlogo {
  width: 150px;
  height: 150px;
  background-color: white;
  border-radius: 20px;
  margin: 20px 20px 20px 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.confirmationtext {
  text-align: left;
  color: var(--tab2_text_color);
  font-family: "Gotham Book";
  font-size: 16px;
  line-height: 16px;
}
</style>
