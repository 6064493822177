<template>
  <div class="overlay-container-main">
    <div class="overlay-container-sub1">
      <div class="header">
        <div class="header-part1">
          <div class="header-back" @click="$emit('back')" />
          <div class="header-logo" />
        </div>
      </div>
      <div class="overlay-container-sub2">
        <div class="pod-photo-container">
          <div
            class="icon"
            :style="{
              'background-image': item.image ? 'url(' + item.image + ')' : null,
            }"
          />
        </div>
        <div class="pod-name">{{ item.name }}</div>
        <div
          class="pod-description"
          v-html="item.description.split('\n').join('<br />')"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
};
</script>

<style scoped>
.header {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.header-part1 {
  display: flex;
  flex-direction: row;
  height: 94px;
  background-color: #ffffff;
}
.header-back {
  border-top: 5px solid #ef7b10;
  flex-grow: 1;
  background-image: url("../../../../img/podcast/vdlande/back.svg");
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.header-logo {
  margin-right: 20px;
  flex-grow: 3;
}
.overlay-container-main {
  position: relative;
  top: 0px;
  left: 0px;
}
.overlay-container-sub1 {
  background-color: #fffffff0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 390px;
  height: 100%;
  padding-bottom: 85px;
}
.overlay-container-sub2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  pointer-events: all;
  align-items: center;
  align-content: center;
  padding: 40px 45px 0px 45px;
}
.pod-photo-container {
  height: 160px;
  margin: 20px;
}
.pod-name {
  font-family: "MetaPro-Bold";
  font-size: 20px;
  line-height: 29px;
  max-height: 60px;
  overflow: hidden;
  margin-bottom: 2px;
  color: #515151;
}
.pod-description {
  font-family: "MetaPro-Norm";
  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
  overflow: auto;
  flex-grow: 1;
  color: #515151;
  padding-top: 15px;
}
.icon {
  width: 160px;
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ef7b21;
}
</style>