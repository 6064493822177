<template>
  <div :style="style" style="text-align: center">
    <div class="you-registration-header">{{ $t("message.participate") }}</div>
    <div class="you-registration-container">
      <form
        @submit="
          (e) => {
            e.preventDefault();
            $emit('register', fields);
          }
        "
      >
        <div v-for="(field, index) in eventInfo.fields" :key="index">
          <input
            type="text"
            :name="field.name"
            :id="field.name"
            :placeholder="field.label"
            v-model="fields[field.name]"
            v-bind:class="['form-control', 'you-register-input']"
          />
        </div>
        <input
          type="submit"
          class="you-register-btn"
          :value="$t('message.anonymousRegisterBtn')"
        />
        <div v-if="errorMsg" class="alert alert-danger" role="alert">
          {{ errorMsg }}
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: ["eventInfo", "errorMsg"],
  data() {
    return {
      fields: {},
      style: {
        "--background_color": this.eventInfo.background_color,
        "--tab1_background_color": this.eventInfo.tab1_background_color,
        "--tab1_text_color": this.eventInfo.tab1_text_color,
        "--tab2_background_color": this.eventInfo.tab2_background_color,
        "--tab2_text_color": this.eventInfo.tab2_text_color,
        "--splash_text_color": this.eventInfo.splash_text_color,
        "--button_color": this.eventInfo.button_color,
        "--button_text_color": this.eventInfo.button_text_color,
        "--button_active_color": this.eventInfo.button_active_color,
        "--button_active_text_color": this.eventInfo.button_active_text_color,
        "--session_block_color": this.eventInfo.session_block_color,
        "--session_block_text_color": this.eventInfo.session_block_text_color,
        "--session_block_active_color":
          this.eventInfo.session_block_active_color,
        "--session_block_active_text_color":
          this.eventInfo.session_block_active_text_color,
        "--registration_text_color": this.eventInfo.registration_text_color,
        "--label_color": this.eventInfo.label_color,
        "--label_text_color": this.eventInfo.label_text_color,
        "--box_background_color": this.eventInfo.box_background_color,
        "--box_input_field_background_color":
          this.eventInfo.box_input_field_background_color,
        "--box_input_field_label_color":
          this.eventInfo.box_input_field_label_color,
        "--box_input_field_text_color":
          this.eventInfo.box_input_field_text_color,
        "--logout_email_text_color": this.eventInfo.logout_email_text_color,
      },
    };
  },
};
</script>
<style type="text/css" scoped>
.you-registration-header {
  margin-left: 10px;
  margin-top: 5px;
  padding-top: 2px;
  font-family: "Gotham Bold";
  color: var(--label_text_color);
  font-size: 24px;
  background-color: var(--label_color);
  width: 195px;
  height: 45px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.you-registration-container {
  position: absolute;
  top: 45px;
  background-color: #dddddd;
  border-radius: 7px;
  width: 286px;
  height: 556px;
  -webkit-box-shadow: -11px 11px 13px 0px rgba(212, 116, 116, 0.35);
  -moz-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  padding: 15px 5px 0px 5px;
}
.you-register-input {
}
.you-register-btn {
  margin-top: 15px;
  flex-grow: 1;
  background-color: var(--button_color);
  border: none;
  border-radius: 10px;
  font-family: "Gotham Bold";
  color: var(--button_text_color);
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  height: 40px;
}
</style>
