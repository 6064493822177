import { render, staticRenderFns } from "./WebsocketClient.vue?vue&type=template&id=63db3a7a&"
import script from "./WebsocketClient.vue?vue&type=script&lang=js&"
export * from "./WebsocketClient.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports