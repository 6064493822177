<template>
  <div>
    <image-uploader
      v-if="!photoSending && !thankyou"
      :maxWidth="1280"
      :maxHeight="1280"
      :quality="0.8"
      :autoRotate="!$device.ios"
      :preview="false"
      className="fileinput"
      :capture="false"
      accept="image/*"
      doNotResize="['gif', 'svg']"
      @input="uploadImage"
    >
      <label for="fileInput" slot="upload-label">
        <figure>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 32 32"
          >
            <path
              d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
              :fill="eventInfo.button_text_color"
            ></path>
          </svg>
        </figure>
      </label>
    </image-uploader>
    <div v-if="photoSending">...</div>
    <div v-if="thankyou">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 24 24"
      >
        <path
          d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
          :fill="eventInfo.button_text_color"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
import WatcherApi from "../mixins/WatcherApi";

export default {
  props: ["eventInfo", "watcher", "redactionToolGuid"],
  mixins: [WatcherApi],
  components: {
    ImageUploader,
  },
  data() {
    return {
      photoSending: false,
      thankyou: false,
    };
  },
  methods: {
    uploadImage(photo) {
      if (this.photoSending || photo.length < 1) {
        return false;
      }
      this.photoSending = true;
      this.apiPost("interaction/" + this.redactionToolGuid + "/photo", {
        photo,
      })
        .then((res) => {
          this.$emit("uploadSuccess");
          this.photoSending = false;
          this.thankyou = true;
          setTimeout(() => {
            this.thankyou = false;
          }, 2000);
        })
        .catch((e) => {
          this.photoSending = false;
        });
    },
  },
};
</script>

<style type="text/css">
input[type="file"] {
  visibility: hidden;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
</style>
