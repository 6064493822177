<template>
  <div>
    <div
      class="bg"
      :style="
        eventInfo.background_color
          ? { 'background-color': eventInfo.background_color }
          : {}
      "
    ></div>
    <div class="page">
      <div class="main-container">
        <stream-frontend
          v-if="eventDetails !== null"
          :eventInfo="eventInfo"
          :eventDetails="eventDetails"
          :watcher="watcher"
          @logout="logout"
        />
        <registration-form
          v-if="!initializing && eventDetails === null"
          :eventInfo="eventInfo"
          :register="register"
          :login="login"
          :fields="fields"
          :errors="errors"
          :loading="loading"
          :finished="finished"
          @retrySso="initiateSsoLogin"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationForm from "../components/RegistrationForm";
import StreamFrontend from "../components/StreamFrontend";
import WatcherApi from "../mixins/WatcherApi";

export default {
  components: {
    RegistrationForm,
    StreamFrontend,
  },
  mixins: [WatcherApi],
  data() {
    return {
      eventInfo: {},
      eventDetails: null,
      watcher: {},
      fields: {},
      errors: [],
      initializing: true,
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.initEvent();
    if ("wakeLock" in navigator) {
      try {
        navigator.wakeLock.request("screen");
      } catch (e) {
        console.log("wakelock request failed");
      }
    }
  },
  methods: {
    getWatcherGuidFromSessionStorage() {
      const guid = window.localStorage.getItem(this.eventInfo.slug + "-guid");
      if (guid) {
        return guid;
      } else {
        return false;
      }
    },
    setWatcherGuidInSessionStorage(guid) {
      window.localStorage.setItem(this.eventInfo.slug + "-guid", guid);
    },
    removeWatcherGuidFromSessionStorage() {
      window.localStorage.removeItem(this.eventInfo.slug + "-guid");
    },
    initEvent() {
      return new Promise((fetchRes) => {
        this.apiGet("info", this.$route.params.slug)
          .then((res) => {
            this.eventInfo = Object.assign(res.data.event_info, {});
            document.title = this.eventInfo.name;
            if (this.eventInfo.language) {
              this.$root.$i18n.locale = this.eventInfo.language;
            }

            const watcherGuid = this.getWatcherGuidFromSessionStorage();
            if (watcherGuid === false) {
              if (this.eventInfo.open_for_all) {
                // Everybody is welcome, register watcher and jump to event
                this.register();
              } else {
                if (this.$route.query.token) {
                  this.loginByToken(this.$route.query.token);
                } else if (this.$route.query.ssotoken) {
                  this.loginBySsoToken(this.$route.query.ssotoken);
                } else if (this.eventInfo.sso_provider) {
                  this.initiateSsoLogin();
                } else {
                  this.initializing = false;
                }
              }
            } else {
              this.initializeWatcher(watcherGuid);
            }
          })
          .catch((e) => {
            console.log(e);
            this.errors = ["Error initializing event data"];
            this.initializing = false;
          });
      });
    },
    initializeWatcher: function (guid) {
      this.apiGet("watcher/details", null, guid)
        .then((res) => {
          this.watcher = Object.assign(res.data.watcher, {});
          this.apiGet("details")
            .then((res) => {
              this.eventDetails = Object.assign(res.data.event_details, {});
              this.initializing = false;
            })
            .catch((e) => {
              console.log(e);
              this.initializing = false;
              this.errors = ["Error initializing event details"];
            });
        })
        .catch((e) => {
          // Failed getting watcher. Probably not logged in, clear guid from session and show form.
          this.initializing = false;
          this.removeWatcherGuidFromSessionStorage();
        });
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    register() {
      this.errors = [];
      const requiredFields = this.eventInfo.fields.filter(
        (field) => field.required
      );

      this.errors = requiredFields.reduce((prev, field) => {
        if (!this.fields[field.name]) {
          prev.push(field.label + " " + this.$t("message.isRequired"));
        }
        return prev;
      }, []);

      if (
        !this.eventInfo.open_for_all &&
        this.eventInfo.agreement_text &&
        !this.fields["agreement"]
      ) {
        this.errors.push(this.$t("message.agreeWithTerms"));
      }

      if (
        !this.eventInfo.open_for_all &&
        !this.validEmail(this.fields["email"])
      ) {
        this.errors.push(this.$t("message.invalidEmailAddress"));
      }

      if (this.errors.length == 0) {
        this.loading = true;
        this.apiPost("watcher/register", {
          email: this.fields["email"],
          fields: this.fields,
        })
          .then((res) => {
            this.loading = false;
            if (res.data.response == "not_on_guestlist") {
              this.errors.push(this.$t("message.unknownEmailAddress"));
            } else if (res.data.response == "email_sent") {
              this.errors.push(this.$t("message.receivedEmailWithLink"));
              this.finished = true;
            } else if (res.data.response == "auth_ok") {
              this.setWatcherGuidInSessionStorage(res.data.guid);
              this.initializeWatcher(res.data.guid);
            } else {
              this.errors.push(this.$t("message.unknownResponse"));
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
            this.errors.push(this.$t("message.registrationProcessingError"));
          });
      }
    },
    login() {
      this.errors = [];
      if (
        !this.eventInfo.open_for_all &&
        !this.validEmail(this.fields["login_email"])
      ) {
        this.errors.push(this.$t("message.invalidEmailAddress"));
      }

      if (this.errors.length == 0) {
        this.loading = true;
        this.apiPost("watcher/loginByEmail", {
          email: this.fields["login_email"],
        })
          .then((res) => {
            this.loading = false;
            if (res.data.response == "not_found") {
              this.errors.push(this.$t("message.loginFailed"));
            } else if (res.data.response == "email_sent") {
              this.errors.push(this.$t("message.receivedEmailWithLink"));
              this.finished = true;
            } else if (res.data.response == "auth_ok") {
              this.setWatcherGuidInSessionStorage(res.data.guid);
              this.initializeWatcher(res.data.guid);
            } else {
              this.errors.push(this.$t("message.unknownResponse"));
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
            this.errors.push(this.$t("message.errorProcessingLogin"));
          });
      }
    },
    loginByToken(token) {
      this.errors = [];

      this.apiPost("watcher/loginByToken", {
        token,
      })
        .then((res) => {
          if (res.data.response == "auth_ok") {
            this.setWatcherGuidInSessionStorage(res.data.guid);
            this.initializeWatcher(res.data.guid);
          } else {
            this.errors.push(this.$t("message.unknownResponse"));
          }
        })
        .catch((e) => {
          this.initializing = false;
          this.errors.push(this.$t("message.errorProcessingLogin"));
        });
    },
    loginBySsoToken(token) {
      this.errors = [];

      this.apiPost("watcher/loginBySsoToken", {
        token,
      })
        .then((res) => {
          if (res.data.response == "auth_ok") {
            this.setWatcherGuidInSessionStorage(res.data.guid);
            this.initializeWatcher(res.data.guid);
            this.$router.replace({
              ...this.$router.currentRoute,
              query: {
                ssotoken: undefined,
              },
            });
          } else if (res.data.response == "not_on_guestlist") {
            this.initializing = false;
            this.errors.push(this.$t("message.unknownEmailAddress"));
          } else {
            this.initializing = false;
            this.errors.push(this.$t("message.unknownResponse"));
          }
        })
        .catch((e) => {
          this.initializing = false;
          this.errors.push(this.$t("message.errorProcessingLogin"));
        });
    },
    initiateSsoLogin() {
      // Make sure to clear podcast sso tokens to prevent mixup of sso methods
      document.cookie = "podcast_token_slug=; path=/";

      window.localStorage.setItem("sso-slug", this.eventInfo.slug);
      window.location.href = `/api/sso/${this.eventInfo.sso_provider}/login`;
    },
    logout() {
      this.removeWatcherGuidFromSessionStorage();
      this.eventDetails = null;
      this.watcher = {};
      this.fields = {};
      this.errors = [];
      this.initializing = false;
      this.loading = false;
      this.finished = false;
      if (this.eventInfo.sso_provider) {
        window.location.href = `/api/sso/${this.eventInfo.sso_provider}/logout`;
      }
    },
  },
};
</script>
<style type="text/css">
body,
html {
  font-family: Arial !important;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
}
.bg {
  position: fixed;
  z-index: -1;
  height: 100%;
  width: 100%;
}
.page {
  position: absolute;
  top: 0px;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
}
.main-container {
  max-width: none;
  width: 100%;
  text-align: center;
}
</style>
