var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(
      !_vm.initializing &&
      !_vm.registered &&
      !_vm.error &&
      _vm.eventInfo &&
      _vm.eventInfo.anonymous_register_form_enabled
    )?_c('div',{staticClass:"interaction"},[_c('anonymous-registration-form',{attrs:{"eventInfo":_vm.eventInfo,"errorMsg":_vm.registerErrorMsg},on:{"register":(fields) =>
          _vm.registerAnonymousWatcher(_vm.$route.params.registertoken, fields)}})],1):_vm._e(),_vm._v(" "),(!_vm.initializing && _vm.registered)?_c('div',{staticClass:"interaction"},[_c('poll-window',{key:_vm.eventDetails.redaction_tool_guid || 'no-redaction-tool',staticStyle:{"position":"absolute"},attrs:{"eventInfo":_vm.eventInfo,"eventDetails":_vm.eventDetails,"watcher":_vm.watcher,"redactionToolGuid":_vm.eventDetails.redaction_tool_guid,"showMobileFooter":true}})],1):_vm._e(),_vm._v(" "),(_vm.error)?_c('div',[_vm._v("\n    Unable to initialize mobile view, please try again at a later time\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }