<template>
  <div>
    <div class="tracks">
      <div class="indicator-container">
        <div class="indicator-area-container">
          <div class="indicator-area">
            <div class="indicator" :style="indicatorCss()"></div>
            <div class="start-indicator"></div>
            <div class="end-indicator"></div>
          </div>
        </div>
        <div class="track-container">
          <div
            class="track"
            v-for="track in tracksWithSessions"
            :key="track.name"
          >
            <div class="track-title">{{ track.name }}</div>
            <div class="track-timeline">
              <div class="session-container">
                <div
                  v-for="session in track.sessions"
                  :key="session.guid"
                  :class="[
                    'session',
                    activeSession && session.guid === activeSession.guid
                      ? 'session-active'
                      : null,
                  ]"
                  :style="
                    'left: ' +
                    sessionStartPercentage(session) +
                    '; width: ' +
                    sessionWidthPercentage(session) +
                    ';'
                  "
                  @click="$emit('playsession', session)"
                  @mousemove="(e) => showDetails(e, session)"
                  @mouseout="hideDetails"
                >
                  <div class="session-info">
                    <div
                      class="session-time"
                      v-if="session.show_time_in_timetable"
                    >
                      {{ session.start_time | moment("HH:mm") }} -
                      {{ session.end_time | moment("HH:mm") }}
                    </div>
                    <div
                      class="session-title"
                      v-if="session.show_name_in_timetable"
                    >
                      {{ session.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="session-details"
      v-if="detailSession !== null"
      :style="'left: ' + detailsDivX + 'px; top: ' + detailsDivY + 'px;'"
      ref="detailsDiv"
    >
      <div class="session-details-name">{{ detailSession.name }}</div>
      <div class="session-details-speaker">{{ detailSession.speaker }}</div>
      <div class="session-details-description">
        {{ detailSession.description }}
      </div>

      <div
        class="session-details-image"
        v-if="detailSession.image"
        :style="{ 'background-image': 'url(' + detailSession.image + ')' }"
      ></div>
    </div>
  </div>
</template>
<script>
const moment = require("moment");
export default {
  props: ["eventInfo", "eventDetails", "sessions", "activeSession"],
  data() {
    return {
      detailsDivX: 0,
      detailsDivY: 0,
      detailSession: null,
    };
  },
  computed: {
    tracksWithSessions() {
      return this.eventDetails.tracks
        .map((track) => {
          return {
            name: track.name,
            sessions: this.sessions.filter(
              (session) => session.track == track.name
            ),
          };
        })
        .filter((track) => {
          return track.sessions.length > 0;
        });
    },
  },
  methods: {
    sessionStartPercentage(session) {
      const minStart = moment.min(
        this.sessions.map((session) => moment(session.start_time))
      );
      const maxEnd = moment.max(
        this.sessions.map((session) => moment(session.end_time))
      );
      const parsed = moment(session.start_time);
      return (
        ((parsed.unix() - minStart.unix()) /
          (maxEnd.unix() - minStart.unix())) *
          100 +
        "%"
      );
    },
    sessionWidthPercentage(session) {
      const minStart = moment.min(
        this.sessions.map((session) => moment(session.start_time))
      );
      const maxEnd = moment.max(
        this.sessions.map((session) => moment(session.end_time))
      );
      const parsedStart = moment(session.start_time);
      const parsedEnd = moment(session.end_time);
      return (
        ((parsedEnd.unix() - parsedStart.unix()) /
          (maxEnd.unix() - minStart.unix())) *
          100 +
        "%"
      );
    },
    indicatorCss() {
      const minStart = moment.min(
        this.sessions.map((session) => moment(session.start_time))
      );
      const maxEnd = moment.max(
        this.sessions.map((session) => moment(session.end_time))
      );
      if (
        minStart.unix() < moment().unix() &&
        maxEnd.unix() > moment().unix()
      ) {
        return (
          "left: " +
          ((moment().unix() - minStart.unix()) /
            (maxEnd.unix() - minStart.unix())) *
            100 +
          "%;"
        );
      } else {
        return "display: none;";
      }
    },
    showDetails(event, session) {
      const offset = 4;
      this.detailsDivY = Math.min(
        event.clientY + offset,
        window.innerHeight - 220
      );
      this.detailsDivX = Math.min(
        event.clientX + offset,
        window.innerWidth - 320
      );
      this.detailSession = session;
      this.$nextTick(() => {
        this.detailsDivY = Math.min(
          event.clientY + offset,
          window.innerHeight - this.$refs.detailsDiv.clientHeight - 20
        );
      });
    },
    hideDetails(event) {
      this.detailSession = null;
    },
  },
};
</script>
<style type="text/css" scoped>
.tracks {
  margin-top: 10px;
}
.indicator-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.indicator-area-container {
  height: 100%;
  width: 100%;
}
.indicator-area {
  position: absolute;
  left: 150px;
  right: 0px;
  height: 100%;
  width: auto;
}
.start-indicator {
  z-index: 5;
  position: absolute;
  height: 100%;
  background-color: var(--tab1_text_color);
  width: 4px;
  height: 100%;
  left: 0px;
}
.end-indicator {
  z-index: 5;
  position: absolute;
  height: 100%;
  background-color: var(--tab1_text_color);
  width: 4px;
  height: 100%;
  right: 0px;
}
.indicator {
  z-index: 5;
  position: absolute;
  height: 100%;
  background-color: var(--tab1_text_color);
  width: 1px;
  height: 100%;
}
.track-container {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}
.track {
  min-height: 70px;
  margin-bottom: 8px;
  display: flex;
  flex-flow: row;
  align-items: stretch;
}
.track-title {
  vertical-align: center;
  width: 150px;
  text-transform: uppercase;
  color: var(--tab1_text_color);
  font-size: 18px;
  border-left: 2px solid var(--tab1_text_color);
  border-top: 2px solid var(--tab1_text_color);
  border-bottom: 2px solid var(--tab1_text_color);
  font-family: "Gotham Bold";
  padding: 20px 0px 10px 0px;
}
.track-timeline {
  flex-grow: 1;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 20px,
    var(--tab1_background_color) 20px,
    var(--tab1_background_color) 22px
  );
  border-top: 2px solid var(--tab1_text_color);
  border-bottom: 2px solid var(--tab1_text_color);
}
.session-container {
  position: relative;
  height: 100%;
}
.session {
  position: absolute;
  cursor: pointer;
  background-color: var(--session_block_color);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--session_block_text_color);
}
.session-active {
  background-color: var(--session_block_active_color);
  color: var(--session_block_active_text_color);
}

.session-info {
  min-height: 50px;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.session-time {
  font-family: "Gotham Bold";
  font-size: 12px;
}
.session-title {
  font-family: "Gotham Bold";
  font-size: 12px;
}
.session-details {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  color: white;
  top: 0px;
  left: 0px;
  min-width: 300px;
  min-height: 200px;
  max-width: 400px;
  text-align: center;
  padding: 10px;
  z-index: 6;
  pointer-events: none;
}
.session-details-name {
  color: white;
  font-size: 22px;
  font-family: "Gotham Bold";
}
.session-details-speaker {
  color: white;
  font-size: 18px;
  font-family: "Gotham Bold";
}
.session-details-description {
  color: white;
  font-size: 20px;
  font-family: "Gotham Book";
}
.session-details-image {
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5px auto 5px auto;
}
</style>
