<template>
  <div style="height: 100%">
    <iframe
      v-if="iframeSrc "
      :src="iframeSrc"
      width="100%"
      height="100%"
      allow="microphone; camera; fullscreen"
    ></iframe>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["eventInfo", "eventDetails", "activeSession", "watcher"],
  data() {
    return {
      iframeSrc: null,
    };
  },
  created() {},
  mounted() {
    this.iframeSrc =
      "/" +
      this.eventInfo.slug +
      "/zoom" +
      (this.activeSession ? "/" + this.activeSession.guid : "");
  },
  methods: {},
};
</script>
<style type="text/css" scoped>
</style>
