import Vue from "vue";
import Router from "vue-router";

// Views
import SsoRedirect from "../views/SsoRedirect";
import EventFrontend from "../views/EventFrontend";
import ZoomFrontend from "../views/ZoomFrontend";
import MobileInteractionFrontend from "../views/MobileInteractionFrontend";
import Podcast from "../views/Podcast";

Vue.use(Router);

export default new Router({
    mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: "/podcast",
            name: "PodcastRoot",
            redirect: to => {
                return { path: '/podcast/test' }
            },
        },
        {
            path: "/podcast/:seriesslug",
            name: "Podcast",
            component: Podcast
        },
        {
            path: "/podcast/:seriesslug/:podcastguid",
            name: "PodcastEpisode",
            component: Podcast
        },
        {
            path: "/sso-redirect",
            name: "SsoRedirect",
            component: SsoRedirect,
        },
        {
            path: "/:slug",
            name: "EventFrontend",
            component: EventFrontend,
        },
        {
            path: "/:slug/zoom",
            name: "ZoomFrontend",
            component: ZoomFrontend,
        },
        {
            path: "/:slug/zoom/:session",
            name: "ZoomFrontend",
            component: ZoomFrontend,
        },
        {
            path: "/:slug/mobile/:watcherguid",
            name: "MobileInteractionFrontend",
            component: MobileInteractionFrontend,
        },
        {
            path: "/:slug/anonymousmobile/:registertoken",
            name: "MobileInteractionFrontend",
            component: MobileInteractionFrontend,
        },
    ],
});
