// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueI18n from "vue-i18n";
import BootstrapVue from "bootstrap-vue";
import App from "./App";
import router from "./router";
import VueLadda from "vue-ladda";
import VueMoment from "vue-moment";
import i18n from "./i18n";
import Pusher from "pusher-js";
import DeviceDetector from "vue-device-detector";

Vue.use(VueMoment);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.component("vue-ladda", VueLadda);
Vue.use(DeviceDetector);

new Vue({
    el: "#app",
    router,
    template: "<App/>",
    i18n: i18n(),
    components: {
        App,
    },
});
