<template>
  <div
    class="you"
    :style="
      eventInfo.page_background_image
        ? { 'background-image': 'url(' + eventInfo.page_background_image + ')' }
        : {}
    "
  >
    <div class="you-container" :style="style">
      <div
        class="you-logo"
        :style="
          eventInfo.page_logo_image
            ? { 'background-image': 'url(' + eventInfo.page_logo_image + ')' }
            : {}
        "
        @click="clearSession"
      ></div>
      <div class="you-topright" v-if="watcher.fields.email">
        <div class="you-email">{{ watcher.fields.email.substr(0, 8) }}...</div>
        <div class="you-logout" @click="logout">
          <svg
            class="you-logout-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35 28"
          >
            <polygon
              points="19,0 31.3,0 31.3,27 19,27 19,21.1 26.2,21 26.3,5.7 19,5.7 "
              :fill="eventInfo.label_text_color"
            />
            <polygon
              points="-0.3,12.9 8.4,3.7 13.8,3.7 13.8,7.3 10.1,11 22.2,11.1 22.2,17 10.4,17 13.9,21 14,23.5 8.1,23.5 "
              :fill="eventInfo.label_text_color"
            />
          </svg>
          <div class="you-logout-txt">
            {{ $t("message.logout") }}
          </div>
        </div>
      </div>
      <div :class="['you-live-indicator']">
        <div class="you-red-dot"></div>
        LIVE
      </div>
      <div class="you-session-name" v-if="activeSession !== null">
        <div class="you-session-name-right"></div>
        <div class="you-session-name-value">
          <div class="you-session-name-label">{{ $t("message.youAreAt") }}</div>
          {{ activeSession.name }}
        </div>
      </div>
      <div
        v-if="activeEmbedCode && displayMode == 'video'"
        :class="['you-video']"
      >
        <div
          class="skip-preroll-btn"
          v-if="preRollActive && activeContent.preroll_skip_text"
          @click="preRollSkipped = true"
        >
          {{ activeContent.preroll_skip_text }}
        </div>
        <div class="video-container" v-html="activeEmbedCode"></div>
      </div>
      <div v-if="displayMode == 'zoom'" class="you-zoom">
        <zoom-meeting
          :watcher="watcher"
          :eventInfo="eventInfo"
          :eventDetails="eventDetails"
          :activeSession="activeSession"
          :key="activeContent.zoom_meeting_id"
        />
      </div>
      <div v-if="displayMode == 'samba'" class="you-samba">
        <samba-session
          :watcher="watcher"
          :eventInfo="eventInfo"
          :eventDetails="eventDetails"
          :activeSession="activeSession"
          :key="activeContent.samba_session_id"
        />
      </div>
      <div class="you-chat" v-if="chatEnabled">
        <chat-window
          :eventInfo="eventInfo"
          :eventDetails="eventDetails"
          :watcher="watcher"
          :activeSession="activeChatSession"
          :key="activeChatSession ? activeChatSession.guid : 'event-chat'"
        />
      </div>
      <div class="you-poll" v-if="interactionEnabled && displayMode != 'zoom'">
        <poll-window
          :redactionToolGuid="redactionToolGuid"
          :key="redactionToolGuid"
          :eventInfo="eventInfo"
          :eventDetails="eventDetails"
          :watcher="watcher"
          :showQr="true"
        />
      </div>
      <div
        :class="['tabcontainer']"
        :style="tabsContainerStyle"
        v-if="sessions.length > 0 || eventDetails.confirmation_text"
      >
        <div :class="['tabs']">
          <div
            class="tab timetablebg"
            v-if="sessions.length > 0"
            @click="activeTab = 'sessions'"
          >
            TIMETABLE
          </div>
          <div
            class="tab eventinfobg"
            @click="activeTab = 'eventinfo'"
            v-if="eventDetails.confirmation_text"
          >
            {{ $t("message.INFORMATION") }}
          </div>
        </div>
        <div class="tabcontent timetablebg" v-if="activeTab == 'sessions'">
          <time-table
            v-if="sessions.length > 0"
            :sessions="sessions"
            :eventInfo="eventInfo"
            :eventDetails="eventDetails"
            :activeSession="activeSession"
            v-on:playsession="playSession"
          />
        </div>
        <div
          class="tabcontent eventinfobg"
          v-if="activeTab == 'eventinfo' && eventDetails.confirmation_text"
        >
          <event-info :eventInfo="eventInfo" :eventDetails="eventDetails" />
        </div>
        <div
          class="you-footer"
          :style="{
            'background-image': eventInfo.footer_image
              ? 'url(' + eventInfo.footer_image + ')'
              : 'url(' + require('../../../img/yoe/footer.png') + ')',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import ChatWindow from "./ChatWindow";
import PollWindow from "./PollWindow";
import TimeTable from "./TimeTable";
import EventInfo from "./EventInfo";
import ZoomMeeting from "./ZoomMeeting";
import SambaSession from "./SambaSession";
import WatcherApi from "../mixins/WatcherApi";

export default {
  components: {
    ChatWindow,
    PollWindow,
    TimeTable,
    EventInfo,
    ZoomMeeting,
    SambaSession,
  },
  props: ["eventInfo", "eventDetails", "watcher"],
  mixins: [WatcherApi],
  data() {
    return {
      style: {
        "--background_color": this.eventInfo.background_color,
        "--tab1_background_color": this.eventInfo.tab1_background_color,
        "--tab1_text_color": this.eventInfo.tab1_text_color,
        "--tab2_background_color": this.eventInfo.tab2_background_color,
        "--tab2_text_color": this.eventInfo.tab2_text_color,
        "--splash_text_color": this.eventInfo.splash_text_color,
        "--button_color": this.eventInfo.button_color,
        "--button_text_color": this.eventInfo.button_text_color,
        "--button_active_color": this.eventInfo.button_active_color,
        "--button_active_text_color": this.eventInfo.button_active_text_color,
        "--session_block_color": this.eventInfo.session_block_color,
        "--session_block_text_color": this.eventInfo.session_block_text_color,
        "--session_block_active_color":
          this.eventInfo.session_block_active_color,
        "--session_block_active_text_color":
          this.eventInfo.session_block_active_text_color,
        "--registration_text_color": this.eventInfo.registration_text_color,
        "--label_color": this.eventInfo.label_color,
        "--label_text_color": this.eventInfo.label_text_color,
        "--box_background_color": this.eventInfo.box_background_color,
        "--box_input_field_background_color":
          this.eventInfo.box_input_field_background_color,
        "--box_input_field_label_color":
          this.eventInfo.box_input_field_label_color,
        "--box_input_field_text_color":
          this.eventInfo.box_input_field_text_color,
        "--logout_email_text_color": this.eventInfo.logout_email_text_color,
      },
      sessions: [],
      activeSession: null,
      activeTab: null,
      heartbeatInterval: null,
      contentRefreshInterval: null,
      contentTimer: 0,
      preRollSkipped: false,
    };
  },
  computed: {
    activeContent() {
      if (this.activeSession) {
        return this.activeSession;
      } else {
        return this.eventDetails;
      }
    },
    activeChatSession() {
      //todo
      return null;
      if (this.activeSession && this.activeSession.use_event_interaction == 0) {
        return this.activeSession;
      } else {
        return null;
      }
    },
    chatEnabled() {
      return this.activeContent.chat_mode !== "disabled";
    },
    redactionToolGuid() {
      if (this.activeSession) {
        return this.activeSession.redaction_tool_guid;
      } else {
        return this.eventDetails.redaction_tool_guid;
      }
    },
    interactionEnabled() {
      return this.redactionToolGuid !== null;
    },
    activeEmbedCode() {
      if (this.preRollActive) {
        return this.activeContent.preroll_embed_code;
      } else {
        return this.activeContent.embed_code;
      }
    },
    preRollActive() {
      return (
        !this.preRollSkipped &&
        this.activeContent.preroll_seconds &&
        this.activeContent.preroll_seconds > 0 &&
        this.contentTimer < this.activeContent.preroll_seconds
      );
    },
    displayMode() {
      if (this.preRollActive) return "video";
      if (this.activeContent.zoom_meeting_id) return "zoom";
      if (this.activeContent.samba_session_id) return "samba";
      if (this.activeContent.embed_code) return "video";
    },
    tabsContainerStyle() {
      return { top: this.displayMode == "samba" ? "1120px" : "820px" };
    },
  },
  mounted() {
    this.sendHeartbeat();
    this.registerHeartbeatInterval(this.eventDetails.content_refresh_interval);
    setInterval(() => {
      this.contentTimer = this.contentTimer + 1;
    }, 1000);
  },
  methods: {
    registerHeartbeatInterval(secInterval) {
      if (secInterval < 10) {
        secInterval = 10;
      }
      if (this.contentRefreshInterval !== secInterval) {
        this.contentRefreshInterval = secInterval;
        if (this.heartbeatInterval) {
          clearInterval(this.heartbeatInterval);
        }
        this.heartbeatInterval = setInterval(
          this.sendHeartbeat,
          secInterval * 1000
        );
      }
    },
    sendHeartbeat: function () {
      this.apiPost("watcher/heartbeat", {})
        .then((res) => {
          this.eventDetails.embed_code = res.data.embed_code;
          this.eventDetails.samba_session_id = res.data.samba_session_id;
          this.eventDetails.zoom_meeting_id = res.data.zoom_meeting_id;
          this.eventDetails.redaction_tool_guid = res.data.redaction_tool_guid;
          this.registerHeartbeatInterval(res.data.content_refresh_interval);
          this.sessions = res.data.sessions;
          if (this.activeSession) {
            const newActiveSession = res.data.sessions.find(
              (session) => this.activeSession.guid == session.guid
            );
            this.activeSession = Object.assign({}, newActiveSession);
          }
          if (this.activeTab === null) {
            this.activeTab =
              this.sessions.length > 0 ? "sessions" : "eventinfo";
          }
          if (this.activeTab == "sessions" && this.sessions.length == 0) {
            this.activeTab = "eventinfo";
          }
        })
        .catch((e) => {
          console.log("heartbeat fail");
          if (e && e.response && e.response.status == 401) {
            // Thrown out?
          }
        });
    },
    playSession: function (session) {
      if (session.external_link) {
        window.open(session.external_link);
        return;
      }
      this.activeSession = Object.assign({}, session);
      this.contentTimer = 0;
      this.preRollSkipped = false;
    },
    clearSession: function () {
      this.activeSession = null;
      this.contentTimer = 0;
      this.preRollSkipped = false;
    },
    logout: function () {
      this.$emit("logout");
    },
  },
};
</script>
<style type="text/css" scoped>
.you {
  display: inline-block;
  width: 1360px;
  background-repeat: no-repeat;
  background-image: url("../../../img/yoe/background.png");
}
.you-container {
  position: relative;
  top: 0px;
  left: 0px;
  min-height: 1170px;
}
.you-logo {
  position: absolute;
  top: 0px;
  left: 30px;
  height: 170px;
  width: 278px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 2;
  background-image: url("../../../img/yoe/yoe-logo-big.png");
  cursor: pointer;
}
.you-topright {
  position: absolute;
  top: 0px;
  left: 760px;
  height: 34px;
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.you-email {
  font-family: "Gotham Book";
  color: var(--logout_email_text_color);
  font-size: 15px;
}
.you-logout {
  padding: 0px 10px 0px 10px;
  margin-left: 10px;
  margin-right: 15px;
  font-family: "Gotham Bold";
  color: var(--label_text_color);
  font-size: 20px;
  background-color: var(--label_color);
  height: 34px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.you-logout-icon {
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

.you-logout-txt {
}

.you-video {
  position: absolute;
  padding: 11px;
  background-color: #dddddd;
  border-radius: 7px;
  -webkit-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  top: 220px;
  left: 30px;
  width: 990px;
  height: 556px;
}
.skip-preroll-btn {
  position: absolute;
  right: 25px;
  top: 15px;
  background-color: white;
  width: max-content;
  z-index: 50;
  cursor: pointer;
  background-color: var(--label_text_color);
  border: none;
  border-radius: 10px;
  font-family: "Gotham Bold";
  font-size: 14px;
  color: var(--label_color);
  padding: 10px 20px;
}
.video-container {
  height: 100%;
}

.you-zoom {
  position: absolute;
  padding: 11px;
  top: 220px;
  left: 30px;
  width: 990px;
  height: 556px;
  background-color: #dddddd;
  border-radius: 7px;
  -webkit-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
}

.you-samba {
  position: absolute;
  padding: 11px;
  top: 220px;
  left: 30px;
  width: 1290px;
  height: 856px;
  background-color: #dddddd;
  border-radius: 7px;
  -webkit-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
  box-shadow: -11px 11px 13px 0px rgba(0, 0, 0, 0.35);
}

.you-live-indicator {
  margin-top: 5px;
  font-family: "Gotham Bold";
  color: var(--label_text_color);
  font-size: 24px;
  position: absolute;
  top: 175px;
  left: 50px;
  background-color: var(--label_color);
  width: 120px;
  height: 45px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 2px;
}
.you-red-dot {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  border-radius: 10px;
  margin-right: 7px;
  vertical-align: middle;
}
.you-session-name {
  text-align: left;
  position: absolute;
  top: 125px;
  left: 386px;
  font-family: "Gotham Bold";
  color: #434c48;
  font-size: 22px;
  display: flex;
  flex-direction: row-reverse;
  width: 650px;
}
.you-session-name-label {
  font-family: "Gotham Book";
  font-size: 22px;
  min-width: 170px;
}
.you-session-name-value {
  padding-top: 5px;
  background-image: url("../../../img/yoe/namecontainer-left.png");
  background-repeat: no-repeat;
  max-width: 600px;
  margin-left: 10px;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 99px;
  width: auto;
  padding-left: 30px;
  white-space: nowrap;
}
.you-session-name-right {
  background-image: url("../../../img/yoe/namecontainer-right.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 99px;
}
.you-chat {
  position: absolute;
  top: 175px;
  left: 1053px;
}
.you-poll {
  position: absolute;
  top: 175px;
  left: 1050px;
}

.tabs {
  left: 0px;
  width: 1360px;
  text-align: left;
  padding-left: 50px;
}

.tab {
  font-family: "Gotham Bold";
  font-size: 18px;
  color: var(--tab1_text_color);
  display: inline-block;
  padding: 2px 20px 2px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 6px;
  cursor: pointer;
}

.tabcontainer {
  position: absolute;
  left: 0px;
  width: 1360px;
}

.tabcontent {
  min-height: 325px;
  padding: 10px 50px 10px 50px;
}

.timetablebg {
  color: var(--tab1_text_color);
  background-color: var(--tab1_background_color);
}

.eventinfobg {
  color: var(--tab2_text_color);
  background-color: var(--tab2_background_color);
}

.you-footer {
  width: 1360px;
  height: 109px;
}
</style>
