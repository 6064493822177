<template>
  <div :class="['itemcontainer', type]" @click="onClick">
    <div
      class="itemleft"
      :style="{
        'background-image': item.image ? 'url(' + item.image + ')' : null,
      }"
    >
      <div v-if="this.is_new" class="new">New!</div>
      <div v-if="this.is_live" class="live">Live!</div>
    </div>
    <div class="itemmain">
      <div :class="['contentcontainer', `contentcontainer-${type}`]">
        <div class="itemtitle">{{ item.name }}</div>
        <div
          :class="['itemcontent', `itemcontent-${type}`]"
          v-html="item.intro.split('\n').join('<br />')"
        ></div>
      </div>
      <div class="playcontainer" v-if="type == 'episode'">
        <div class="continuereading" @click="continueReading" />
        <div
          v-if="!(playingEpisodeGuid == item.guid)"
          class="playbutton"
          @click="playEpisode"
        />
        <div v-else class="pausebutton" @click="pauseEpisode" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item", "type", "is_new", "is_live", "playingEpisodeGuid"],
  methods: {
    onClick() {
      if (this.type == "podcast") {
        this.$emit("openPodcast", this.item);
      }
    },
    playEpisode() {
      this.$emit("playEpisode", this.item);
    },
    pauseEpisode() {
      this.$emit("pauseEpisode", this.item);
    },
    continueReading() {
      this.$emit("showOverlay", this.item);
    },
  },
};
</script>

<style scoped>
.podcast {
  cursor: pointer;
}
.itemcontainer {
  background-color: #ffffff;
  margin: 14px;
  height: 148px;
  display: flex;
  flex-direction: row;
}
.itemleft {
  min-width: 102px;
  background-color: #ef7b21;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.new,
.live {
  background-color: #ef7b21;
  width: 100%;
  color: #ffffff;
  font-family: "MetaPro-Bold";
  font-size: 15px;
  padding: 4px 0px;
  text-align: center;
}
.contentcontainer {
  height: 107px;
}
.contentcontainer-podcast {
  height: 137px;
}
.itemmain {
  margin: 4px 12px;
  width: 100%;
}
.itemtitle {
  font-family: "MetaPro-Bold";
  font-size: 15px;
  line-height: 21px;
  max-height: 42px;
  overflow: hidden;
  margin-bottom: 2px;
}
.itemcontent {
  font-family: "MetaPro-Norm";
  font-size: 10px;
  line-height: 15px;
  height: 60px;
  vertical-align: top;
  overflow: hidden;
}
.itemcontent-podcast {
  height: 108px;
}
.playcontainer {
  display: flex;
  flex-direction: row;
  justify-items: end;
  align-items: flex-end;
}
.continuereading {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-image: url("../../../../img/podcast/vdlande/continuereading.svg");
  background-size: 100px;
  background-position: left center;
  height: 30px;
  width: 90px;
}
.playbutton {
  background-image: url("../../../../img/podcast/vdlande/play.svg");
  background-size: contain;
  height: 30px;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
}
.pausebutton {
  background-image: url("../../../../img/podcast/vdlande/pause.svg");
  background-size: contain;
  height: 30px;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
}
</style>