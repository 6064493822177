<template>
  <div>
    <div id="zmmtg-root"></div>
    <div id="aria-notify-area"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  created() {
    var scripts = [
      "https://source.zoom.us/1.9.0/lib/vendor/react.min.js",
      "https://source.zoom.us/1.9.0/lib/vendor/react-dom.min.js",
      "https://source.zoom.us/1.9.0/lib/vendor/redux.min.js",
      "https://source.zoom.us/1.9.0/lib/vendor/redux-thunk.min.js",
      "https://source.zoom.us/1.9.0/lib/vendor/lodash.min.js",
      "https://source.zoom.us/zoom-meeting-1.9.0.min.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      tag.setAttribute("async", false);
      document.body.appendChild(tag);
    });
    var css = [
      "https://source.zoom.us/1.9.0/css/bootstrap.css",
      "https://source.zoom.us/1.9.0/css/react-select.css",
    ];
    css.forEach((cssfile) => {
      let tag = document.createElement("link");
      tag.setAttribute("href", cssfile);
      tag.setAttribute("type", "text/css");
      tag.setAttribute("rel", "stylesheet");
      document.head.appendChild(tag);
    });
  },
  data() {
    return {
      initialized: false,
      zoomParams: {},
    };
  },
  mounted() {
    const slug = this.$route.params.slug;
    const guid = window.localStorage.getItem(slug + "-guid");
    const url = this.$route.params.session
      ? "/api/frontend/event/" +
        slug +
        "/sessions/" +
        this.$route.params.session +
        "/zoom_params"
      : "/api/frontend/event/" + slug + "/zoom_params";
    axios
      .get(url, {
        headers: {
          Authorization: "watcher " + guid,
        },
      })
      .then((res) => {
        this.zoomParams = res.data;
        setInterval(() => {
          if (!this.initialized && typeof ZoomMtg !== "undefined") {
            this.initialized = true;
            this.init();
          }
        }, 100);
      });
  },
  methods: {
    init() {
      const params = this.zoomParams;
      ZoomMtg.setZoomJSLib(
        "https://dmogdx0jrul3u.cloudfront.net/1.9.0/lib",
        "/av"
      );
      ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.0/lib", "/av");
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareJssdk();
      ZoomMtg.init({
        leaveUrl: "about:blank",
        debug: false,
        isSupportAV: true,
        videoHeader: false,
        disableInvite: true,
        meetingInfo: [],
        success: function () {
          ZoomMtg.join({
            ...params,
            success: function () {
              ZoomMtg.showInviteFunction({ show: false });
              console.log("success");
            },
            error(res) {
              console.log(res);
            },
          });
        },
      });
    },
  },
};
</script>
<style type="text/css">
</style>
