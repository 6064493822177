<template>
  <span v-html="contentRendered"></span>
</template>

<script>
export default {
  props: ["content"],
  computed: {
    contentRendered() {
      return this.wrapURLs(this.content, true);
    },
  },
  data() {
    return {};
  },
  methods: {
    wrapURLs(text, new_window) {
      var url_pattern = /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/gi;
      var target = new_window === true || new_window == null ? "_blank" : "";

      return text.replace(url_pattern, function (url) {
        var protocol_pattern = /^(?:(?:https?|ftp):\/\/)/i;
        var href = protocol_pattern.test(url) ? url : "http://" + url;
        return '<a href="' + href + '" target="' + target + '">' + url + "</a>";
      });
    },
  },
};
</script>

<style type="text/css" scoped>
</style>
